export default class FiltersMap {
    _lang;
    _defaultFilterConfig = {
        enabled: false,
        from: "",
        to: "",
        round_limit_time: false,
        ban_type: "",
        notice_before_ban_count: 0,
        warning_message: "",
        limit_interval: "",
        ban_notice: false,
        ban_message: "",
        remove: false,
        limit: 0,
        remove_timeout: 0,
        new_limit: 0,
        new_limit_msg_count: 0,
        first_message_remove: false,
        first_message_ban_type: "",
        resolve_from_rank: 0,
        take_away_reputation: 0,
        take_away_xp: 0,

    };
    sticker_pack = {
        sticker_pack: { ...this._defaultFilterConfig },
        sticker_pack_items: [],
        journal_moderation_warn_sticker_pack: false,
        journal_moderation_ban_sticker_pack: false
    }
    stop_word = {
        stop_words: [],
        stop_word_strict_mode: false,
        stop_word_handle_modified: false,
        stop_word: {
            enabled: false,
            from: "",
            to: "",
            round_limit_time: false,
            ban_type: "",
            notice_before_ban_count: 0,
            warning_message: "",
            limit_interval: "",
            ban_notice: false,
            ban_message: "",
            remove: false,
            limit: 0,
            ignore_caption: false,
            remove_timeout: 0,
            new_limit: 0,
            new_limit_msg_count: 0,
            first_message_remove: false,
            first_message_ban_type: "",
            remove_edited: false,
            remove_edited_timeout: 0,
            remove_edited_msg: 0,
        },
    };
    flood = {
        flood: { ...this._defaultFilterConfig },
        /* Extra settings */
        flood_min_message_interval: 0,
        flood_message_count: 0,
        flood_message_interval: 0,
        flood_remove_if_prev_user_text: false,
        flood_remove_if_prev_global_text: false,
        flood_remove_if_prev_user_audio: false,
        flood_remove_if_prev_global_audio: false,
        flood_remove_if_prev_user_document: false,
        flood_remove_if_prev_global_document: false,
        flood_remove_if_prev_user_sticker: false,
        flood_remove_if_prev_global_sticker: false,
        flood_remove_if_prev_user_photo: false,
        flood_remove_if_prev_global_photo: false,
        flood_remove_if_prev_user_video: false,
        flood_remove_if_prev_global_video: false,
        flood_remove_if_prev_user_animation: false,
        flood_remove_if_prev_global_animation: false,
        flood_remove_if_prev_user_contact: false,
        flood_remove_if_prev_global_contact: false,
        flood_remove_if_prev_user_voice: false,
        flood_remove_if_prev_global_voice: false,
        flood_remove_if_prev_user_videonote: false,
        flood_remove_if_prev_global_videonote: false,
        flood_remove_if_prev_user_poll: false,
        flood_remove_if_prev_global_poll: false,
        flood_remove_if_prev_user_dice: false,
        flood_remove_if_prev_global_dice: false,
    };
    self_links = {
        tg_links_whitelist: [],
        self_links: {
            enabled: false,
            from: "",
            to: "",
            round_limit_time: false,
            ban_type: "",
            notice_before_ban_count: 0,
            warning_message: "",
            limit_interval: "",
            ban_notice: "",
            ban_message: "",
            remove: false,
            limit: 0,
            ignore_caption: false,
            remove_timeout: 0,
            new_limit: 0,
            new_limit_msg_count: 0,
            first_message_remove: false,
            first_message_ban_type: "",
            remove_edited: false,
            remove_edited_timeout: 0,
            remove_edited_msg: 0,
        },
        /* Extra settings */
        self_links_dont_use_dog: false,
        self_links_dont_use_join: false,
        self_links_dont_use_tme: false,

        self_links_dont_use_channels: false,
        self_links_dont_use_chats: false,
        self_links_dont_use_users: false,
        self_links_dont_use_bots: false

    };
    email_links = {
        email_links: {
            enabled: false,
            from: "",
            to: "",
            round_limit_time: false,
            ban_type: "",
            notice_before_ban_count: 0,
            warning_message: "",
            limit_interval: "",
            ban_notice: false,
            ban_message: "",
            remove: false,
            limit: 0,
            ignore_caption: false,
            remove_timeout: 0,
            new_limit: 0,
            new_limit_msg_count: 0,
            first_message_remove: false,
            first_message_ban_type: "",
        },
    };
    sticker = {
        sticker: {
            enabled: false,
            from: "",
            to: "",
            round_limit_time: false,
            ban_type: "",
            notice_before_ban_count: "",
            warning_message: "",
            limit_interval: "",
            ban_notice: false,
            ban_message: "",
            remove: false,
            limit: 0,
            remove_timeout: 0,
            new_limit: 0,
            new_limit_msg_count: 0,
            first_message_remove: false,
            first_message_ban_type: "",
        },
    };
    voice = {
        voice: {
            enabled: false,
            from: "",
            to: "",
            voice_ban_type: "",
            voice_notice_before_ban_count: 0,
            warning_message: "",
            voice_limit_interval: "",
            ban_notice: false,
            ban_message: "",
            remove: false,
            limit: 0,
            remove_timeout: 0,
            new_limit: 0,
            new_limit_msg_count: 0,
            first_message_remove: 0,
            first_message_ban_type: "",
        },
        /* Extra settings */
        voice_min_duaration: 0,
        voice_max_duaration: 0,
        voice_min_file_size: 0,
        voice_max_file_size: 0,

    };
    images = {
        images: {
            ...this._defaultFilterConfig,
            remove_edited: false,
            remove_edited_timeout: 0,
            remove_edited_msg: 0,
        },
        /* Extra settings */
        images_min_file_size: 0,
        images_max_file_size: 0,
        images_min_width: 0,
        images_max_width: 0,
        images_min_height: 0,
        images_max_height: 0,
    };
    gif = {
        gif: {
            enabled: false,
            from: 0,
            to: 0,
            ban_type: "",
            notice_before_ban_count: 0,
            warning_message: "",
            limit_interval: "",
            ban_notice: false,
            ban_message: "",
            remove: false,
            limit: 0,
            remove_timeout: 0,
            new_limit: 0,
            new_limit_msg_count: 0,
            first_message_remove: false,
            first_message_ban_type: "",
            remove_edited: "",
            remove_edited_timeout: 0,
            remove_edited_msg: 0,
        },
        /* Extra settings */
        gif_min_duaration: 0,
        gif_max_duaration: 0,
        gif_min_file_size: 0,
        gif_max_file_size: 0,
        gif_min_width: 0,
        gif_max_width: 0,
        gif_min_height: 0,
        gif_max_height: 0,
    };
    audio = {
        audio: {
            ...this._defaultFilterConfig,
            remove_edited: false,
            remove_edited_timeout: 0,
            remove_edited_msg: 0,
        }
    };
    video = {
        video: {
            ...this._defaultFilterConfig,
            remove_edited: false,
            remove_edited_timeout: 0,
            remove_edited_msg: 0,
        },
        /* Extra settings */
        video_min_duaration: 0,
        video_max_duaration: 0,
        video_min_file_size: 0,
        video_max_file_size: 0,
        video_min_width: 0,
        video_max_width: 0,
        video_min_height: 0,
        video_max_height: 0,
    };
    files = {
        files: {
            ...this._defaultFilterConfig,
            remove_edited: false,
            remove_edited_timeout: 0,
            remove_edited_msg: 0,
        },
        /* Extra settings */
        files_extension_list: "",
        files_mime_type_list: "",
        files_min_file_size: 0,
        files_max_file_size: 0,
    };
    contact = {
        contact: {
            ...this._defaultFilterConfig,
            remove_edited: false,
            remove_edited_timeout: 0,
            remove_edited_msg: 0,
        }
    };
    warn = {
        warn: {
            enabled: false,
            from: "",
            to: "",
            round_limit_time: false,
            ban_type: "",
            notice_before_ban_count: 0,
            warning_message: "",
            limit_interval: "",
            ban_notice: false,
            ban_message: "",
            remove: false,
            remove_timeout: 0,
            first_message_remove: false,
            first_message_ban_type: "",
        },
    };
    rtl_message = {
        rtl_message: {
            enabled: false,
            from: "",
            to: "",
            round_limit_time: false,
            ban_type: "",
            notice_before_ban_count: 0,
            warning_message: "",
            limit_interval: "",
            ban_notice: false,
            ban_message: "",
            remove: false,
            limit: 0,
            ignore_caption: false,
            remove_timeout: 0,
            new_limit: 0,
            new_limit_msg_count: 0,
            first_message_remove: false,
            first_message_ban_type: "",
        },
        /* Extra settings */
        rtl_message_max_percent: 0,
    };
    caps_message = {
        caps_message_strict_mode: false,
        caps_message: {
            enabled: false,
            from: "",
            to: "",
            round_limit_time: false,
            ban_type: "",
            notice_before_ban_count: 0,
            warning_message: "",
            limit_interval: "",
            ban_notice: false,
            ban_message: "",
            remove: false,
            limit: 0,
            ignore_caption: false,
            remove_timeout: 0,
            new_limit: 0,
            new_limit_msg_count: 0,
            first_message_remove: false,
            first_message_ban_type: "",
        },
    };
    bot_reply = {
        bots_for_reply_whitelist: [],
        bot_reply_by_via: false,
        bot_reply_by_user: false,
        bot_reply: {
            enabled: false,
            from: "",
            to: "",
            round_limit_time: false,
            ban_type: "",
            notice_before_ban_count: 0,
            warning_message: "",
            limit_interval: "",
            ban_notice: false,
            ban_message: "",
            remove: false,
            limit: 0,
            ignore_caption: false,
            remove_timeout: 0,
            new_limit: 0,
            new_limit_msg_count: 0,
            first_message_remove: false,
            first_message_ban_type: "",
        },
    };
    bot_command = {
        bot_commands_whitelist: [],
        bot_command: {
            enabled: false,
            from: "",
            to: "",
            round_limit_time: false,
            ban_type: "",
            notice_before_ban_count: 0,
            warning_message: "",
            limit_interval: "",
            ban_notice: false,
            ban_message: "",
            remove: false,
            limit: 0,
            ignore_caption: false,
            remove_timeout: 0,
            new_limit: 0,
            new_limit_msg_count: 0,
            first_message_remove: false,
            first_message_ban_type: "",
        },
        /* Extra settings */
        bot_commands_strict_mode: false,
    };
    channel_reply = {
        channels_whitelist: [],
        channel_reply: {
            enabled: false,
            from: "",
            to: "",
            round_limit_time: false,
            ban_type: "",
            notice_before_ban_count: 0,
            warning_message: "",
            limit_interval: "",
            ban_notice: false,
            ban_message: "",
            remove: "",
            limit: 0,
            ignore_caption: false,
            remove_timeout: 0,
            new_limit: 0,
            new_limit_msg_count: 0,
            first_message_remove: false,
            first_message_ban_type: "",
        },
    };
    chat_reply = {
        chat_reply: {
            enabled: false,
            from: "",
            to: "",
            round_limit_time: false,
            ban_type: "",
            notice_before_ban_count: 0,
            warning_message: "",
            limit_interval: 0,
            ban_notice: false,
            ban_message: "",
            remove: false,
            limit: 0,
            ignore_caption: false,
            remove_timeout: 0,
            new_limit: 0,
            new_limit_msg_count: false,
            first_message_remove: false,
            first_message_ban_type: "",
        },
    };
    channel_message = {
      channel_message_whitelist: [],
      channel_message: { ...this._defaultFilterConfig }
    };
    any_message = {
        any_message: {
            enabled: false,
            from: "",
            to: "",
            ban_type: "",
            notice_before_ban_count: 0,
            warning_message: "",
            limit_interval: "",
            ban_notice: "",
            ban_message: "",
            remove: false,
            limit: 0,
            new_limit: 0,
            new_limit_msg_count: 0,
            any_message_handler_type: "",
            remove_edited: false,
            remove_edited_timeout: 0,
            remove_edited_msg: 0,
        },
    };
    permitted_word = {
        permitted_words: [],
        permitted_word_strict_mode: false,
        permitted_word: { ...this._defaultFilterConfig },
    };
    regular_expression = {
        regular_expressions: [],
        regular_expression_strict_mode: false,
        regular_expression: {
            enabled: false,
            from: "",
            to: "",
            round_limit_time: false,
            ban_type: false,
            notice_before_ban_count: 0,
            warning_message: "",
            limit_interval: "",
            ban_notice: false,
            ban_message: "",
            remove: false,
            limit: 0,
            ignore_caption: false,
            remove_timeout: 0,
            new_limit: 0,
            new_limit_msg_count: 0,
            first_message_remove: false,
            first_message_ban_type: "",
        },
    };
    video_note = {
        video_note: {
            ...this._defaultFilterConfig,
            remove_edited: false,
            remove_edited_timeout: 0,
            remove_edited_msg: 0,
        },
        /* Extra settings */
        video_note_min_duaration: 0,
        video_note_max_duaration: 0,
        video_note_min_file_size: 0,
        video_note_max_file_size: 0,
    };
    hieroglyph_message = {
        hieroglyph_message: {
            enabled: false,
            from: 0,
            to: 0,
            ban_type: "",
            notice_before_ban_count: 0,
            warning_message: "",
            limit_interval: "",
            ban_notice: false,
            ban_message: "",
            remove: false,
            limit: 0,
            remove_timeout: 0,
            new_limit: 0,
            new_limit_msg_count: 0,
            first_message_remove: false,
            first_message_ban_type: "",
            remove_edited: false,
            remove_edited_timeout: 0,
            remove_edited_msg: 0,
        },
        /* Extra settings */
        hieroglyph_message_max_percent: 0,

    };
    message_length = {
        message_length: {
            enabled: false,
            from: "",
            to: "",
            round_limit_time: false,
            ban_type: "",
            notice_before_ban_count: 0,
            warning_message: "",
            limit_interval: 0,
            ban_notice: false,
            ban_message: false,
            remove: false,
            limit: 0,
            remove_timeout: 0,
            new_limit: 0,
            new_limit_msg_count: 0,
            first_message_remove: false,
            first_message_ban_type: "",
            remove_edited: false,
            remove_edited_timeout: 0,
            remove_edited_msg: 0,
        },
        /* Extra settings */
        message_length_min: 0,
        message_length_max: 0,
        message_length_words_count_min: 0,
        message_length_words_count_max: 0,

    };
    dice = {
        dice: {
            ...this._defaultFilterConfig
        },
    };
    referral = {
        referral: {
            enabled: false,
            from: "",
            to: "",
            round_limit_time: false,
            ban_type: "",
            notice_before_ban_count: 0,
            warning_message: "",
            limit_interval: 0,
            ban_notice: false,
            ban_message: "",
            remove: false,
            limit: 0,
            remove_timeout: 0,
            new_limit: 0,
            new_limit_msg_count: 0,
            first_message_remove: false,
            first_message_ban_type: "",
            remove_edited: false,
            remove_edited_timeout: 0,
            remove_edited_msg: 0,
        },
    };
    spoiler = {
        spoiler: {
            ...this._defaultFilterConfig
        },
        spoiler_max_size: 60,
        spoiler_min_words_count: 16,
        spoiler_min_message_len: 0,
        spoiler_allow_message_begin: false,
        spoiler_allow_message_end: true
    };

    static filtersList = [
        "sticker_pack",
        "stop_word",
        "flood",
        "self_links",
        "email_links",
        "sticker",
        "voice",
        "images",
        "gif",
        "audio",
        "video",
        "files",
        "contact",
        "warn",
        "rtl_message",
        "caps_message",
        "bot_reply",
        "bot_command",
        "channel_reply",
        "chat_reply",
        "any_message",
        "permitted_word",
        "regular_expression",
        "video_note",
        "hieroglyph_message",
        "message_length",
        "dice",
        "referral",
        "censor_word",
        "web_links",
	"channel_message",
        "spoiler"
    ];

    constructor(lang) {
        this._lang = lang;
    }

    get censor_word() {
        const returnObject = {
            censor_word_strict_mode: false,
            censor_word_use_custom_dict: false,
            censor_word_custom_dict: [],
            censor_word: Object.assign(this._defaultFilterConfig),
        };
        if (this._lang === "ru") {
            returnObject.censor_word_fuck = false;
            returnObject.censor_word_govno = false;
            returnObject.censor_word_jopa = false;
            returnObject.censor_word_mudak = false;
        }
        return returnObject;
    }

    get web_links() {
        const returnObject = {
            accept_http_links_whitelist: [],
            web_links_strict_mode: false,
            web_links: Object.assign(
                this._defaultFilterConfig,
                {
                    remove_edited: false,
                    remove_edited_timeout: 0,
                    remove_edited_msg: 0,
                },
            ),
            /* Extra settings */
            accept_http_links_from_top_100: false,
            web_links_dont_check_ips: false
        };
        if (this._lang === "ru") {
            returnObject.accept_http_links_from_top_ru_100 = false;
        }
        return returnObject;
    }

}
