import Api                 from "@/includes/logic/Api";
import { errorNotification } from "@/includes/NotificationService";

export default {
  requestGetFlows({ commit }, boardId) {
    return new Promise(resolve => {
      Api.getFlows("tg", { board: boardId })
	 .then(({ data }) => {
	   commit("set_flows_to_store", data.items);
	   resolve(data.items);
	 })
	 .catch(errorNotification)
    });
  },

  swapChatBrand({ state, commit }, param) {
    if(param === 'flow') {
      if(state.chatState.brandConfig.hellow) {
	commit('swap_chat_brand', { flow: {} })
      }
    } else if (param === 'message') {
      if(state.chatState.brandConfig.flow) {
	commit('swap_chat_brand', { hellow: 'Hi!' })
      }
    }
  },

  swapBrandFlow({ commit }, flow) {
    commit('set_flow_to_brand', flow)
  },

  addFlowInStore({ commit }, { key, title }) {
    commit('add_flow', { key, title })
  },

  deleteFlowFromState({ commit }, { index }) {
    commit('delete_flow', { index })
  }
};
