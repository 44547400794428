


















import ScriptLoader from "./includes/helpers/ScriptLoader";
import i18n from "@/i18n/i18n";

import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { hasMatchedRouteParams } from "piramis-base-components/src/components/SelectEntityWizard/includes/helpers";
import SelectEntityWizard from 'piramis-base-components/src/components/SelectEntityWizard/SelectEntityWizard.vue'

import Vue from "vue";
import axios from 'axios'
import { isEqual } from "lodash";
import ruRu from "ant-design-vue/lib/locale-provider/ru_RU";
import enUS from 'ant-design-vue/lib/locale-provider/en_US'
import { Component } from 'vue-property-decorator'

@Component({
  components: {
    SelectEntityWizard
  }
})
export default class App extends Vue {
  defaultLocaleSet = false

  translationsLoaded = false

  map = {
    [EntityTypes.CHAT_ID]: () => {
      return this.$store.dispatch('requestChatsInfo')
        .then(({ data }:any) => {
          return data.chats
            .filter((chat:any) => chat.member_count !== null)
            .filter((chat:any) => !chat.hidden)
            .sort((a:any, b:any) => b.member_count - a.member_count)
            .map((c:any) => ({ id: c.chat_id, title: c.group_title, avatar: c.group_photo }))

        })
    },
  }

  get containsSelectConstants():boolean {
    return hasMatchedRouteParams(this.$route)
  }

  get currentLocale() {
    if (this.$i18n.locale === 'ru') {
      return ruRu
    }

    if (this.$i18n.locale === 'en') {
      return enUS
    }

    return ruRu
  }

  beforeWindowUnload(e:BeforeUnloadEvent) {
    if (this.$store.state.chatState.chat && this.$store.state.chatState.backupConfig !== null) {
      if (!isEqual(this.$store.state.chatState.chat.config, this.$store.state.chatState.backupConfig.config)) {
        return e.returnValue = this.$t('config_not_saved_alert').toString();
      }
    }
  }

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  }

  mounted() {
    window.addEventListener('beforeunload', this.beforeWindowUnload)

    // Global site tag (gtag.js) - Google Statistic
    new ScriptLoader([ 'https://www.googletagmanager.com/gtag/js?id=UA-154663624-1' ], () => {
      (window as any).dataLayer = (window as any).dataLayer || [];

      function gtag(..._:Array<any>) {
        (window as any).dataLayer.push(arguments);
      }

      gtag("js", new Date());
      gtag("config", "UA-154663624-1");
    })
      .require()

    axios.get(`/translations/common.json`).then(({ data }) => {

      // TODO: FIX IT Нужно в одном месте хранить переводы

      i18n.mergeLocaleMessage('ru', data['ru'])
      i18n.mergeLocaleMessage('en', data['en'])

      this.$i18n.mergeLocaleMessage('ru', data['ru'])
      this.$i18n.mergeLocaleMessage('en', data['en'])

      this.translationsLoaded = true;
    }).catch(console.error)
  }
}

