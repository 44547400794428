export default class ReturnUrl {
	
	static PROPERTY_NAME = "returnUrl";
	
	static get isExists() {
		return localStorage.getItem(this.PROPERTY_NAME) !== null;
	}
	
	static get url() {
		return localStorage.getItem(this.PROPERTY_NAME);
	}
	
	/**
	 *
	 * @param url {String}
	 */
	static set(url) {
		localStorage.setItem(this.PROPERTY_NAME, url);
	}
	
	static delete() {
		localStorage.removeItem(this.PROPERTY_NAME);
	}
	
	static go() {
		if(this.isExists) {
			location.href = this.url;
			this.delete();
		} else {
			console.error("Return Url doesn`t exists");
		}
	}
	
}
