export default {
  topicsStructureIfForum:(state) => (boardTokenId, targetId) => {
    const board = state.postsPlanner.boards.find(b => b.board.token_id === Number.parseInt(boardTokenId))

    if (board) {
      const target = board.targets.find(t => t.id === targetId)

      if ('topics' in target) {
        return []
      } else {
        return null
      }
    } else {
      throw 'Board not exists'
    }
  },
  topicsIfForum:(state) => (boardTokenId, targetId) => {
    const board = state.postsPlanner.boards.find(b => b.board.token_id === Number.parseInt(boardTokenId))

    if (board) {
      const target = board.targets.find(t => t.id === targetId)

      if ('topics' in target) {
	return target.topics.map(t => ({
	  label: t.title,
	  value: t.id
	}))
      } else {
	return undefined
      }
    } else {
      throw 'Board not exists'
    }
  }
}
