export default {
	journalModerationAllWarn(state) {
		state.chatState.chat.config.journal_moderation_all_warn = +!(+(+state.chatState.chat.config.journal_moderation_all_warn));
	},
	journalModerationAllBan(state) {
		state.chatState.chat.config.journal_moderation_all_ban = +!(+state.chatState.chat.config.journal_moderation_all_ban);
	},
	journalFacecontrol(state) {
		state.chatState.chat.config.journal_facecontrol = +!(+state.chatState.chat.config.journal_facecontrol);
	},
	journalUsers(state) {
		state.chatState.chat.config.journal_users = +!(+state.chatState.chat.config.journal_users);
	},
	journalSystem(state) {
		state.chatState.chat.config.journal_system = +!(+state.chatState.chat.config.journal_system);
	},
	journalChatActions(state) {
		state.chatState.chat.config.journal_chat_actions = +!(+state.chatState.chat.config.journal_chat_actions);
	},
	journalModerationWarnCensorWord(state) {
		state.chatState.chat.config.journal_moderation_warn_censor_word = +!(+state.chatState.chat.config.journal_moderation_warn_censor_word);
	},
	journalModerationWarnStopWord(state) {
		state.chatState.chat.config.journal_moderation_warn_stop_word = +!(+state.chatState.chat.config.journal_moderation_warn_stop_word);
	},
	journalModerationWarnFlood(state) {
		state.chatState.chat.config.journal_moderation_warn_flood = +!(+state.chatState.chat.config.journal_moderation_warn_flood);
	},
	journalModerationWebLinks(state) {
		state.chatState.chat.config.journal_moderation_warn_web_links = +!(+state.chatState.chat.config.journal_moderation_warn_web_links);
	},
	journalModerationSelfLinks(state) {
		state.chatState.chat.config.journal_moderation_warn_self_links = +!(+state.chatState.chat.config.journal_moderation_warn_self_links);
	},
	journalModerationWarnSticker(state) {
		state.chatState.chat.config.journal_moderation_warn_sticker = +!(+state.chatState.chat.config.journal_moderation_warn_sticker);
	},
	journalModerationWarnVoice(state) {
		state.chatState.chat.config.journal_moderation_warn_voice = +!(+state.chatState.chat.config.journal_moderation_warn_voice);
	},
	journalModerationWarnImages(state) {
		state.chatState.chat.config.journal_moderation_warn_images = +!(+state.chatState.chat.config.journal_moderation_warn_images);
	},
	journalModerationWarnGif(state) {
		state.chatState.chat.config.journal_moderation_warn_gif = +!(+state.chatState.chat.config.journal_moderation_warn_gif);
	},
	journalModerationWarnAudio(state) {
		state.chatState.chat.config.journal_moderation_warn_audio = +!(+state.chatState.chat.config.journal_moderation_warn_audio);
	},
	journalModerationWarnVideo(state) {
		state.chatState.chat.config.journal_moderation_warn_video = +!(+state.chatState.chat.config.journal_moderation_warn_video);
	},
	journalModerationWarnFiles(state) {
		state.chatState.chat.config.journal_moderation_warn_files = +!(+state.chatState.chat.config.journal_moderation_warn_files);
	},
	journalModerationWarnContact(state) {
		state.chatState.chat.config.journal_moderation_warn_contact = +!(+state.chatState.chat.config.journal_moderation_warn_contact);
	},
	journalModerationWarnWarn(state) {
		state.chatState.chat.config.journal_moderation_warn_warn = +!(+state.chatState.chat.config.journal_moderation_warn_warn);
	},
	journalModerationWarnRtlMessage(state) {
		state.chatState.chat.config.journal_moderation_warn_rtl_message = +!(+state.chatState.chat.config.journal_moderation_warn_rtl_message);
	},
	journalModerationWarnCapsMessage(state) {
		state.chatState.chat.config.journal_moderation_warn_caps_message = +!(+state.chatState.chat.config.journal_moderation_warn_caps_message);
	},
	journalModerationWarnBotReply(state) {
		state.chatState.chat.config.journal_moderation_warn_bot_reply = +!(+state.chatState.chat.config.journal_moderation_warn_bot_reply);
	},
	journalModerationWarnBotCommand(state) {
		state.chatState.chat.config.journal_moderation_warn_bot_command = +!(+state.chatState.chat.config.journal_moderation_warn_bot_command);
	},
	journalModerationWarnChannelReply(state) {
		state.chatState.chat.config.journal_moderation_warn_channel_reply = +!(+state.chatState.chat.config.journal_moderation_warn_channel_reply);
	},
	journalModerationWarnChatReply(state) {
		state.chatState.chat.config.journal_moderation_warn_chat_reply = +!(+state.chatState.chat.config.journal_moderation_warn_chat_reply);
	},
	journalModerationWarnAnyMessage(state) {
		state.chatState.chat.config.journal_moderation_warn_any_message = +!(+state.chatState.chat.config.journal_moderation_warn_any_message);
	},
	journal_moderation_ban_censor_word(state) {
		state.chatState.chat.config.journal_moderation_ban_censor_word = +!(+state.chatState.chat.config.journal_moderation_ban_censor_word);
	},
	journal_moderation_ban_stop_word(state) {
		state.chatState.chat.config.journal_moderation_ban_stop_word = +!(+state.chatState.chat.config.journal_moderation_ban_stop_word);
	},
	journal_moderation_ban_flood(state) {
		state.chatState.chat.config.journal_moderation_ban_flood = +!(+state.chatState.chat.config.journal_moderation_ban_flood);
	},
	journal_moderation_ban_web_links(state) {
		state.chatState.chat.config.journal_moderation_ban_web_links = +!(+state.chatState.chat.config.journal_moderation_ban_web_links);
	},
	journal_moderation_ban_self_links(state) {
		state.chatState.chat.config.journal_moderation_ban_self_links = +!(+state.chatState.chat.config.journal_moderation_ban_self_links);
	},
	journal_moderation_ban_email_links(state) {
		state.chatState.chat.config.journal_moderation_ban_email_links = +!(+state.chatState.chat.config.journal_moderation_ban_email_links);
	},
	journal_moderation_ban_sticker(state) {
		state.chatState.chat.config.journal_moderation_ban_sticker = +!(+state.chatState.chat.config.journal_moderation_ban_sticker);
	},
	journal_moderation_ban_voice(state) {
		state.chatState.chat.config.journal_moderation_ban_voice = +!(+state.chatState.chat.config.journal_moderation_ban_voice);
	},
	journal_moderation_ban_images(state) {
		state.chatState.chat.config.journal_moderation_ban_images = +!(+state.chatState.chat.config.journal_moderation_ban_images);
	},
	journal_moderation_ban_gif(state) {
		state.chatState.chat.config.journal_moderation_ban_gif = +!(+state.chatState.chat.config.journal_moderation_ban_gif);
	},
	journal_moderation_ban_audio(state) {
		state.chatState.chat.config.journal_moderation_ban_audio = +!(+state.chatState.chat.config.journal_moderation_ban_audio);
	},
	journal_moderation_ban_video(state) {
		state.chatState.chat.config.journal_moderation_ban_video = +!(+state.chatState.chat.config.journal_moderation_ban_video);
	},
	journal_moderation_ban_files(state) {
		state.chatState.chat.config.journal_moderation_ban_files = +!(+state.chatState.chat.config.journal_moderation_ban_files);
	},
	journal_moderation_ban_contact(state) {
		state.chatState.chat.config.journal_moderation_ban_contact = +!(+state.chatState.chat.config.journal_moderation_ban_contact);
	},
	journal_moderation_ban_warn(state) {
		state.chatState.chat.config.journal_moderation_ban_warn = +!(+state.chatState.chat.config.journal_moderation_ban_warn);
	},
	journal_moderation_ban_rtl_message(state) {
		state.chatState.chat.config.journal_moderation_ban_rtl_message = +!(+state.chatState.chat.config.journal_moderation_ban_rtl_message);
	},
	journal_moderation_ban_caps_message(state) {
		state.chatState.chat.config.journal_moderation_ban_caps_message = +!(+state.chatState.chat.config.journal_moderation_ban_caps_message);
	},
	journal_moderation_ban_bot_reply(state) {
		state.chatState.chat.config.journal_moderation_ban_bot_reply = +!(+state.chatState.chat.config.journal_moderation_ban_bot_reply);
	},
	journal_moderation_ban_bot_command(state) {
		state.chatState.chat.config.journal_moderation_ban_bot_command = +!(+state.chatState.chat.config.journal_moderation_ban_bot_command);
	},
	journal_moderation_ban_channel_reply(state) {
		state.chatState.chat.config.journal_moderation_ban_channel_reply = +!(+state.chatState.chat.config.journal_moderation_ban_channel_reply);
	},
	journal_moderation_ban_chat_reply(state) {
		state.chatState.chat.config.journal_moderation_ban_chat_reply = +!(+state.chatState.chat.config.journal_moderation_ban_chat_reply);
	},
	journal_moderation_ban_any_message(state) {
		state.chatState.chat.config.journal_moderation_ban_any_message = +!(+state.chatState.chat.config.journal_moderation_ban_any_message);
	},
	// facecontrol
	facecontrol_username_remove_bots(state) {
		state.chatState.chat.config.facecontrol_username_remove_bots = +!(+state.chatState.chat.config.facecontrol_username_remove_bots);
	},
	facecontrol_username_ban_bot_add_user(state) {
		state.chatState.chat.config.facecontrol_username_ban_bot_add_user = +!(+state.chatState.chat.config.facecontrol_username_ban_bot_add_user);
	},
	facecontrol_username_enabled(state) {
		state.chatState.chat.config.facecontrol_username_enabled = +!(+state.chatState.chat.config.facecontrol_username_enabled);
	},
	facecontrol_username_ban_type(state, val) {
		state.chatState.chat.config.facecontrol_username_ban_type = val;
	},
	facecontrol_username_min_len(state, val) {
		state.chatState.chat.config.facecontrol_username_min_len = val;
	},
	facecontrol_username_max_len(state, val) {
		state.chatState.chat.config.facecontrol_username_max_len = val;
	},
	facecontrol_username_rtl_enabled(state) {
		state.chatState.chat.config.facecontrol_username_rtl_enabled = +!(+state.chatState.chat.config.facecontrol_username_rtl_enabled);
	},
	facecontrol_username_rtl_percent(state, val) {
		state.chatState.chat.config.facecontrol_username_rtl_percent = val;
	},
	deferred_remove_ban_bot_messages(state) {
		state.chatState.chat.config.deferred_remove_ban_bot_messages = +!(+state.chatState.chat.config.deferred_remove_ban_bot_messages);
	},
	remove_system_voice_chat_messages(state, { value }) {
		state.chatState.chat.config.remove_system_voice_chat_messages = value;
	},
	warning_message(state, val) {
		state.chatState.chat.config.warning_message = val;
	},
	new_user_limit(state, val) {
		state.chatState.chat.config.new_user_limit = val;
	},
	ban_time_1(state, val) {
		state.chatState.chat.config.ban_time_1 = val;
	},
	ban_time_2(state, val) {
		state.chatState.chat.config.ban_time_2 = val;
	},
	ban_time_3(state, val) {
		state.chatState.chat.config.ban_time_3 = val;
	},
	ban_time_4(state, val) {
		state.chatState.chat.config.ban_time_4 = val;
	},
	ban_time_5(state, val) {
		state.chatState.chat.config.ban_time_5 = val;
	},
	system_message_interval(state, val) {
		state.chatState.chat.config.system_message_interval = val;
	},
	do_not_send_messages_in_row(state) {
		state.chatState.chat.config.do_not_send_messages_in_row = +!(+state.chatState.chat.config.do_not_send_messages_in_row);
	},
	automatic_text_correction(state) {
		state.chatState.chat.config.automatic_text_correction = +!(+state.chatState.chat.config.automatic_text_correction);
	},
	remove_edited(state) {
		state.chatState.chat.config.remove_edited = +!(+state.chatState.chat.config.remove_edited);
	},
	remove_edited_timeout(state, val) {
		state.chatState.chat.config.remove_edited_timeout = val;
	},
	remove_edited_msg(state, val) {
		state.chatState.chat.config.remove_edited_msg = val;
	},
};
