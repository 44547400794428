import moment                from "moment";
import { cloneDeep, toPairs, uniq }                     from "lodash";
import camelToUnderscoreCase from "@/assets/utils/camelToUnderscoreCase";

export default class DashboardStatAdapter {
	_DEFAULT_DATA_VALUE = 0;
	_dashboardRawData;
	_fromDateTime;
	_toDateTime;
	_dateTimesArr       = [];
	_dateTimeFormat     = "YYYY-MM-DD";
	_prefix             = "dashboard_statistics_";
	$i18n;

	constructor(dashboardRawData, from, to, $i18n) {
		this._dashboardRawData = dashboardRawData;
		this._fromDateTime     = from;
		this._toDateTime       = to;
		this.$i18n             = $i18n;
		this._fillDataTimesArr();
	}

	_fillDataTimesArr() {
		const from = moment(this._fromDateTime);
		const to   = moment(this._toDateTime);

		while(!from.isAfter(to)) {
			this._dateTimesArr.push(from.format(this._dateTimeFormat));
			from.add(1, "day");
		}
	}

	_processTimeValueData(keys) {
		const returnStatistics   = {};
		const standardDataObject = {
			total : 0,
			series: [ {
				name: null,
				data: this._dateTimesArr.map(() => this._DEFAULT_DATA_VALUE),
			} ],
			labels: this._dateTimesArr.slice(),
		};

		keys
			.forEach(key => {
				returnStatistics[ key ]                  = cloneDeep(standardDataObject);
				returnStatistics[ key ].series[ 0 ].name = this.$i18n.t(this._prefix + camelToUnderscoreCase(key));
				if(this._dashboardRawData && this._dashboardRawData[ key ]) {
					this._dashboardRawData[ key ].forEach(dataItem => {
						returnStatistics[ key ].total += dataItem.value;
						returnStatistics[ key ].series[ 0 ]
							.data[ returnStatistics[ key ].labels.indexOf(dataItem.time) ] = dataItem.value;
					});
				}
			});
		return returnStatistics;
	}

	_processFiltersStatistics() {
		const stat               = {};
		const standardDataObject = {
			total : 0,
			series: [],
			labels: this._dateTimesArr.slice(),
		};

		for(let [ filterKey, filterData ] of Object.entries(this._dashboardRawData.filters)) {
			stat[ filterKey ]            = {};
			stat[ filterKey ].data       = cloneDeep(standardDataObject);
			stat[ filterKey ].data.total = Object.values(filterData).reduce((a, b) => a + b);
			stat[ filterKey ].data.series.push({
				name: this.$i18n.t(filterKey + "_enabled"),
				data: (() => {
					const arr = new Array(this._dateTimesArr.length).fill(this._DEFAULT_DATA_VALUE);
					for(let [ time, value ] of Object.entries(filterData)) {
						arr[ standardDataObject.labels.indexOf(time) ] = value;
					}
					return arr;
				})(),
			});
		}

		return stat;
	}

	_processModerationStatistics() {
		const returnStatistics   = {};
		const standardDataObject = {
			name               : "",
			totalUserIdsCount  : this._DEFAULT_DATA_VALUE,
			totalActuationCount: this._DEFAULT_DATA_VALUE,
			usersStatData      : null,
		};
		[ "automatic_filter_target", "manual_filter_target", "manual_filter_actor" ]
			.forEach(key => {
				returnStatistics[ key ]      = cloneDeep(standardDataObject);
				returnStatistics[ key ].name = this.$i18n.t(this._prefix + camelToUnderscoreCase(key));
				if(this._dashboardRawData[ key ]) {
					returnStatistics[ key ].usersStatData     = this._dashboardRawData[ key ];
					returnStatistics[ key ].totalUserIdsCount = Object.values(this._dashboardRawData[ key ]).length;
					if(Object.values(this._dashboardRawData[ key ]).length > 0) {
						returnStatistics[ key ].totalActuationCount = Object.values(this._dashboardRawData[ key ])
						.reduce((accumulator, currentValue) => accumulator + currentValue);
					}
				}
			});
		return returnStatistics;
	}

	getAllUsersId() {
		const usersIdArr      = [];
		const userIdArrPusher = userId => {
			if(!usersIdArr.includes(userId)) {
				usersIdArr.push(parseInt(userId));
			}
		};
		[ "reputation_target_count", "reputation_actor_count", "reputation_target_sum", "reputation_actor_sum", "total_active_users",
			"trigger_filter_target", "trigger_filter_actor", "manual_filter_actor", "manual_filter_target", "automatic_filter_target" ]
			.forEach(key => {
				if(this._dashboardRawData[ key ]) {
					for(let user_id in this._dashboardRawData[ key ]) {
						if(this._dashboardRawData[ key ].hasOwnProperty(user_id)) userIdArrPusher(user_id);
					}
				}
			});

		if(this._dashboardRawData[ "active_users" ]) {
			this._dashboardRawData[ "active_users" ].forEach(dataItem => {
				userIdArrPusher(dataItem.user_id);
			});
		}
		if(this._dashboardRawData[ "reputation_users" ]) {
			this._dashboardRawData[ "reputation_users" ].forEach(dataItem => {
				userIdArrPusher(dataItem.actor_user_id);
				userIdArrPusher(dataItem.target_user_id);
			});
		}
		return usersIdArr;
	}

	getDataTimesArr() {
		return this._dateTimesArr;
	}

	getData() {
		const ratesKeys         = [ "FacecontrolRemoveBot", "FacecontrolRemoveBotUser", "EnterNewMember", "ReturnNewMember", "CommandUnwarn", "CommandReslim",
																"CommandReport", "CommandOn", "CommandOff", "CommandMute", "CommandKick", "CommandBan", "CommandUnban", "CommandUnmute",
																"CommandWarn", "CommandRooff", "CommandRo", "CommandDellog", "CommandRoall", "CommandRepin", "CommandUnrepin", "CommandBotaddadmin",
																"CommandBotdeladmin", "CommandSiteaddadmin", "CommandSitedeladmin", "FacecontrolRtl", "FacecontrolMinLen", "FacecontrolMaxLen",
																"GuardEditedMessage", "GuardNewLimit", "InactiveKick", "TriggerMute", "TriggerKick", "TriggerBan", "TriggerUnban",
																"TriggerUnmute", "TriggerWarn", "TriggerTriggerWarn", "CommandUn", "FacecontrolHieroglyph", "FacecontrolLangCode",
																"FacecontrolLoginMinLen", "FacecontrolLoginMaxLen", "UpdateSettings", "ReportAction", "RemoveUserByMathCaptcha", "UserCompleteCaptcha" ];
		const combinedRatesKeys = [ "trigger_punish", "trigger_unpunish", "manual_admin_moderation", "manual_user_moderation", "new_user_enter", "new_user_kicked",
																"new_user_allowed", "facecontrol", "guard", "commands", "trigger_call", "reputation_call" ];
		return {
			combinedRates: this._processTimeValueData(combinedRatesKeys),
			rates        : this._dashboardRawData.rates ? this._processTimeValueData(ratesKeys) : null,
			// moderationStatistics: this._processModerationStatistics(),
			filter       : this._processFiltersStatistics(),
			users: {
				active_users: this._processActiveUsersStatistics(),
				total_active_users: this._processTotalActiveUsersStatistics()
			},
			reputation: {
				reputation_users: this._processReputationUsers()
			},
			dateTimes    : this.getDataTimesArr(),
		};
	}

	_processActiveUsersStatistics() {
		return {
			series: [ (() => ({
				name: this.$i18n.t(this._prefix + "active_users"),
				data: (() => {
					const arr = new Array(8).fill(0);
					this._dashboardRawData.active_users.forEach(user => {
						arr[this._dateTimesArr.indexOf(user.time)] += 1
					})
					return arr
				})()
			}))() ],
			labels: this._dateTimesArr.slice(),
		};
	}

	_processTotalActiveUsersStatistics() {
		return {
			total: Object.values(this._dashboardRawData.total_active_users).length ? Object.values(this._dashboardRawData.total_active_users).reduce((a, b) => a + b) : 0,
			users: toPairs(this._dashboardRawData.total_active_users).sort((a, b) => b[1] - a[1])
		}
	}

	_processReputationUsers() {
		return {
			series: [ ...(() => {
				const tmpActors = {};
				const tmpTargets = {};
				this._dateTimesArr.forEach(time => {
					tmpActors[time] = []
					tmpTargets[time] = []
				})
				this._dashboardRawData.reputation_users.forEach(user => {
					tmpActors[user.time].push(user.actor_user_id)
					tmpTargets[user.time].push(user.target_user_id)
				})
				return [ {
					name: this.$i18n.t(this._prefix + "reputation_actors"),
					data: Object.values(tmpActors).map(arr => uniq(arr).length)
				}, {
					name: this.$i18n.t(this._prefix + "reputation_targets"),
					data: Object.values(tmpTargets).map(arr => uniq(arr).length)
				} ]
			})() ],
			labels: this._dateTimesArr.slice()
		}
	}
}
