import { cloneDeep } from "lodash";

export default {
	updateChatsInfo(state, n) {
		state.chatState.chatsInfo = n;
	},
	updateChat(state, n) {
		state.chatState.chat = n;

		// Put the copy of an actual config for rolling back
		state.chatState.backupConfig = cloneDeep(n);
	},
	updateChatLang(state, n) {
		state.chatState.chat.language_code = n;
	},
	removePreview(state) {
		state.chatState.chat.config.remove_preview = +!(+state.chatState.chat.config.remove_preview);
	},
	useExperimentalMethods(state) {
		state.chatState.chat.config.use_experimental_methods = +!(+state.chatState.chat.config.use_experimental_methods);
	},
	botSilentMode(state) {
		state.chatState.chat.config.bot_silent_mode = +!(+state.chatState.chat.config.bot_silent_mode);
	},
	enableRulesCommand(state) {
		state.chatState.chat.config.enable_rules_command = +!(+state.chatState.chat.config.enable_rules_command);
	},
	gods_admins(state) {
		state.chatState.chat.config.gods_admins = +!(+state.chatState.chat.config.gods_admins);
	},
	report_to_admins(state) {
		state.chatState.chat.config.report_to_admins = +!(+state.chatState.chat.config.report_to_admins);
	},
	report_remove(state) {
		state.chatState.chat.config.report_remove = +!(+state.chatState.chat.config.report_remove);
	},
	enable_rules_command(state) {
		state.chatState.chat.config.enable_rules_command = +!(+state.chatState.chat.config.enable_rules_command);
	},
	send_smile_message(state) {
		state.chatState.chat.config.send_smile_message = +!(+state.chatState.chat.config.send_smile_message);
	},
	godsAdmins(state) {
		state.chatState.chat.config.gods_admins = +!(+state.chatState.chat.config.gods_admins);
	},
	enableReportCommand(state) {
		state.chatState.chat.config.enable_report_command = +!(+state.chatState.chat.config.enable_report_command);
	},
	sendSmileMessage(state) {
		state.chatState.chat.config.send_smile_message = +!(+state.chatState.chat.config.send_smile_message);
	},
	sendWakeupMessage(state) {
		state.chatState.chat.config.send_wakeup_message = +!(+state.chatState.chat.config.send_wakeup_message);
	},
	sendAdminMessage(state) {
		state.chatState.chat.config.send_admin_message = +!(+state.chatState.chat.config.send_admin_message);
	},
	description(state, val) {
		state.chatState.chat.config.description = val;
	},
	rules(state, val) {
		state.chatState.chat.config.rules = val;
	},
	addAdmin(state, val) {
		state.chatState.chat.admins = val;
	},
	removeAdmin(state, val) {
		const arr   = state.chatState.chat.admins;
		const index = arr.indexOf(val);

		if(index > -1) {
			arr.splice(index, 1);
		}
	},
	addChatAdmins(state, val) {
		state.chatState.chat.chatAdmins = val;
	},
	removeChatAdmins(state, val) {
		const arr   = state.chatState.chat.chatAdmins;
		const index = arr.indexOf(val);

		if(index > -1) {
			arr.splice(index, 1);
		}
	},
	addReportAdmins(state, val) {
		state.chatState.chat.reportAdmins = val;
	},
	removeReportAdmins(state, val) {
		const arr   = state.chatState.chat.reportAdmins;
		const index = arr.indexOf(val);

		if(index > -1) {
			arr.splice(index, 1);
		}
	},
	livenUpTimeout(state, val) {
		state.chatState.chat.config.liven_up_timeout = val;
	},
	wakeupMessage(state, val) {
		state.chatState.chat.config.wakeup_message = val;
	},
	adminMessageInterval(state, val) {
		state.chatState.chat.config.admin_message_interval = val;
	},
	adminMessage(state, val) {
		state.chatState.chat.config.admin_message = val;
	},
};
