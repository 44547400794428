import moderation       from "./chat/moderation/state";
import chatRuntimeState from "./chatRuntime/state";
import statistics       from "./statistics/state";
import questionnaire    from "./questionnaire/state";

export default {
	chatsInfo                  : {},
	chat                       : {},
	haveChats                  : true,
	brandConfig                : null,
	backupConfig               : null,
	...chatRuntimeState,
	...moderation,
	...statistics,
	displaySaveChatConfigButton: true,
	questionnaire,
};
