export default {
	ban_command_text(state, val) {
		state.chatState.chat.config.ban_command_text = val;
	},
	ban_command_reply(state) {
		state.chatState.chat.config.ban_command_reply = +!(+state.chatState.chat.config.ban_command_reply);
	},
	ban_command_remove(state) {
		state.chatState.chat.config.ban_command_remove = +!(+state.chatState.chat.config.ban_command_remove);
	},
};
