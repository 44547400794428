// import themeConfig            from "@/../themeConfig.js";
// import colors                 from "@/../themeConfig.js";

// import authData      	from "./auth/authConfig";
import chatState     	from "./chats/state";
import triggersState 	from "./triggers/state";
import userState     	from "./user/state";
import Config       	from "@/config";
import postsPlanner 	from './postsPlanner/state';
import flows		from './flow/state';

const state = {
  // breadcrumbs: [],
  // isSidebarActive       : true,
  // breakpoint            : null,
  // sidebarWidth          : "default",
  // reduceButton          : themeConfig.sidebarCollapsed,
  // bodyOverlay           : false,
  // loginTimeout	        : null,
  // sidebarItemsMin       : false,
  // theme                 : themeConfig.theme || "light",
  // themePrimaryColor: colors.primary,
  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar component
  // windowWidth: null,
  // Include auth store
  // authData     : authData,
  // Chats state
  chatState    : chatState,
  // User state
  userState    : userState,
  // triggers
  triggersState: triggersState,
  themeConfig  : Config,
  //posts-planner state
  postsPlanner : postsPlanner,
  // flows state
  flows: flows,
};

export default state;
