import i18n from "@/i18n/i18n";
import TemplateContext from 'piramis-base-components/src/plugins/AdminTemplate/includes/TemplateContext'
import store from "@/store/store";
import { Route } from "vue-router";
import { Board } from "@/views/posts-planner/posts.types";

export function actionTypeBreadcrumb(ctx: TemplateContext):string {
  let label = ''

  if (ctx?.route.params.actionType === 'new') label = i18n.t('page_action_type_new').toString()
  if (ctx?.route.params.actionType === 'edit') label = i18n.t('page_action_type_edit').toString()
  if (ctx?.route.params.actionType === 'watch') label = i18n.t('page_action_type_watch').toString()

  return label
}

export const baseChatBreadcrumbs = () => [
  {
    iconName: 'icon-message-circle',
    routeName: 'chats',
  },
  {
    routeName: 'dashboard',
    image: () => (store.state.chatState.chat as any).group_photo,
    label: () => (store.state.chatState.chat as any).group_title + " (" + (store.state.chatState.chat as any).chat_id + ")"
  },
]

export function baseBoardBreadcrumbs (ctx: Route) {
  const currentBoard: Board | undefined = store.state.postsPlanner.boards.find((board:Board) => board.board.token_id.toString() === ctx.params.boardId)

  if (currentBoard !== undefined) {

    return [
      {
        iconName: 'icon-message-circle',
        routeName: 'boards',
      },
      {
        routeName: 'posts_planner',
        image: () => (currentBoard as Board).avatar,
        label: () => (currentBoard as Board).title + " (" + (currentBoard  as Board).board.token_id + ")"
      },
    ]
  }

  return []
}
