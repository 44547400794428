import { cloneDeep }           from "lodash";
import banCommand  from "../banCommand";
import kickCommand from "../kickCommand";
import muteCommand from "../muteCommand";

export default {
	// Специальные фильтры
	...muteCommand,
	...kickCommand,
	...banCommand,
	// ---
	configCopyMutation(state, payload) {
	  if (payload && payload.config) {
	    state.chatState.configCopy = payload.config
	  } else {
	    state.chatState.configCopy = cloneDeep(state.chatState.chat.config);
	  }
	},
	mergeConfigsMutation(state, n) {
		state.chatState.chat.config = cloneDeep(n);
	},
};
