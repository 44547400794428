import "@/assets/css/main.css";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "material-icons/iconfont/material-icons.css";

import axios from "axios";
import VShowSlide from "v-show-slide";
import { extend, localize } from "vee-validate";
// @ts-ignore
import en from "vee-validate/dist/locale/en";
// @ts-ignore
import ru from "vee-validate/dist/locale/ru";
import { email, integer, numeric, required } from "vee-validate/dist/rules";
import Vue, { PluginFunction } from "vue";
// @ts-ignore
import checkView from "vue-check-view";
import { DynamicReactiveRefs, ReactiveRefs } from "vue-reactive-refs";
import VueScreen from "vue-screen";
// @ts-ignore
import { VueHammer } from "vue2-hammer";
import App from "./App.vue";
import "./assets/scss/main.scss";
import setUniqueStringInArray from "./assets/utils/setUniqueStringInArray";
import "./globalComponents.js";
import i18n from "./i18n/i18n";
import router from "./router/router";
import store from "./store/store";
import isMobile from "@/assets/utils/isMobile";

import RouteHelpers from 'piramis-base-components/src/plugins/RouteHelpers/index'
import AdminTemplate from 'piramis-base-components/src/plugins/AdminTemplate'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import 'piramis-base-components/src/assets/scss/main.scss'
import 'video.js/dist/video-js.min.css'
import { NavigationGuardNext } from "vue-router/types/router";
import { Route } from "vue-router";
import { AdminTemplateOptions, Layout } from "piramis-base-components/src/plugins/AdminTemplate/includes/types/Config";
import { MenuItemType } from "piramis-base-components/src/plugins/AdminTemplate/includes/types/MenuItem";
import { isEmpty } from "lodash";
import { getTariffTranslationKey } from "@/includes/helpers/tariffHelper";
import { Board } from "@/views/posts-planner/posts.types";
import { actionTypeBreadcrumb, baseBoardBreadcrumbs, baseChatBreadcrumbs } from "@/includes/AppConfigBreadcrumbsHelper";
import { Guid } from "guid-typescript";
import { EntityTypes } from "piramis-base-components/src/components/SelectEntityWizard/includes/types";
import { ProductNames } from "piramis-base-components/src/plugins/AdminTemplate/components/OurProducts/types";

localize({
  ru,
  en,
});

localize(i18n.locale);

extend("required", required);
extend("email", email);
extend("numeric", numeric);
extend("integer", integer);
extend("url", {
  validate(value) {
    // eslint-disable-next-line
    return value.text(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi);
  },
});

// @ts-ignore
extend('is_not_0', { validate: (val) => val !== 0, message: i18n.t('validation_is_not_0') })

// Vue.use(Commerce as unknown as PluginFunction<any>, { store })
Vue.use(RouteHelpers as unknown as PluginFunction<any>, { router })

Vue.use(Antd)
Vue.use(VShowSlide);
Vue.use(checkView);
Vue.use(VueScreen, "tailwind");
Vue.use(require("vue-scrollto"));
Vue.prototype.$http = axios;
Vue.use(VueHammer);
Vue.use(DynamicReactiveRefs);
Vue.use(ReactiveRefs);
(Vue as any).VuSetUniqueStringInArray = setUniqueStringInArray;

// Feather font icon
require("./assets/css/iconfont.css");

const adminTemplateOptions: AdminTemplateOptions = {
  config: {
    footer: {
      version: (store.state as any).metaModule.PACKAGE_VERSION,
      ourProducts: {
        exclude: [
            ProductNames.CHATKEEPERBOT,
        ]
      }
    },
    meta: {
      projectName: process.env.VUE_APP_TARGET_PROJECT === 'infibot' ? 'Infibot.app' : 'ChatKeeper.app',
      apiUrl: 'api.chatkeeper.app/cabinet',
      projectDomain: 'https://chatkeeper.app',
      sidebarLogo: process.env.VUE_APP_TARGET_PROJECT === 'infibot' ? require('./assets/images/infibot/logo/logo.png') : require('./assets/images/logo/logo.png'),
      loginPageLogo: process.env.VUE_APP_TARGET_PROJECT === 'infibot' ? require('./assets/images/infibot/logo/logo-full.png') : require('./assets/images/logo/text-logo-dark.png'),
      tgResolvers: {
        supportBotDomain: 'ChatKeeperBotSupport',
        promotionBotDomain: 'ChatKeeperPromotion',
        mainBotDomain: 'ChatKeeperBot',
      },
      login: {
        // botAuthLink: 'https://t.me/ChatKeeperBot?start=cabinetlink',
        botAuthLink: 'https://t.me/ChatKeeperBot?start=cabinetlinkbeta',
        loginWidget: {
          botLogin: 'chatkeeperbot',
          tgWidgetAuthLink: 's/tg/d/login?bot_id=553147242',
        }
      },
      languageCodes: [ 'ru', 'en' ],
      isSaveButtonVisible: true,
      paymentEntity: {
        target_type: (ctx) => {
          return {
            target_id: {
              chat_id: (store.state.chatState.chat as any)?.chat_id,
            },
            target_type: 'chat'
          }
        }
      },
    },
    profile: {
      advertiseButton: () => 'https://cabinet.chatkeeper.app/web/tgresolve/en/ChatKeeperPromotion%3Fstart%3Dloyalty_parnters'
    },
    loyaltySystem: {
      partnerBlock: {
        partnerLink: (localeCode) => 'https://cabinet.chatkeeper.app/web/tgresolve/en/ChatKeeperPromotion%3Fstart%3Dloyalty_parnters'
      },
      companiesBlock: {
        termsOwnerLink: (localeCode) => 'https://cabinet.chatkeeper.app/web/tgresolve/en/ChatKeeperPromotion%3Fstart%3Dloyalty_parnters'
      }
    },
    companyRegistration: {
      powerOfAttorney: (localeCode): string => 'https://chatkeeper.app/wp-content/uploads/2021/08/power_of_attorney_ur.doc',
      howToRegisterUr: (localeCode): string => 'https://chatkeeper.app/wp-content/uploads/2022/08/how_to_register_ur-4.docx',
    },
    search: {
      entities: (ctx) => {
        const chatsList:any = (store.state.chatState.chatsInfo as any)?.chats

        if (chatsList) {
          return chatsList.slice().map((c:any) => ({ avatar: c.group_photo, title: c.group_title, id: c.chat_id })) || []
        }
      },
      path: (ctx, router, selectedId) => {
        router.push({
          name: 'dashboard',
          params: {
            [EntityTypes.CHAT_ID]: selectedId
          }
        })
      }
    },
    rightScreenButton: {
      link: (localeCode) => {
        if (localeCode === 'ru') return 'https://t.me/PressCodeSupportRu'
        else return 'https://t.me/PressCodeSupport'
      },
    },
    serviceData: {
      type: 'ServiceData',
      tariffs: {
        starter: {
          type: 'TariffInfo',
          img: require('./assets/images/payments/tariffs/chatkeeper/starter.png'),
          color: 'rgb(177,69,250)',
        },
        advanced: {
          type: 'TariffInfo',
          img: require('./assets/images/payments/tariffs/chatkeeper/advanced.png'),
          color: 'rgb(177,69,250)',
        },
        ultimate: {
          type: 'TariffInfo',
          img: require('./assets/images/payments/tariffs/chatkeeper/ultimate.png'),
          color: 'rgb(177,69,250)',
        },
      },
    },
    topMenu: [
      {
        menuItem: {
          guid: Guid.create().toString(),
          type: MenuItemType.DropDown,
          name: 'help',
          featherIcon: true,
          icon: 'help-circle',
          isVisible: [ true ],
          class: [ 'text-warning' ],
          submenu: [
            {
              menuItem: {
                type: MenuItemType.DropDownLink,
                isVisible: [ true ],
                url: () => 'https://youtube.com',
                featherIcon: true,
                icon: 'youtube',
                name: 'help_youtube'
              }
            },
            {
              menuItem: {
                type: MenuItemType.DropDownLink,
                isVisible: [ true ],
                url: () => 'https://t.me/ChatKeeperBotSupport',
                featherIcon: true,
                icon: 'life-buoy',
                name: 'help_tech_support'
              }
            },
            {
              menuItem: {
                type: MenuItemType.DropDownLink,
                isVisible: [ true ],
                url: () => 'https://docs.chatkeeper.app/ru/',
                featherIcon: true,
                icon: 'book-open',
                name: 'help_instruction'
              }
            },
            {
              menuItem: {
                isVisible: [ true ],
                type: MenuItemType.DropDownLinkComponent,
                name: 'regexp',
                icon: 'flag',
                featherIcon: true,
                component: (): any => import('piramis-base-components/src/plugins/AdminTemplate/components/regexp-validator/RegexpValidator.vue'),
              },
            },
          ],
        },
      },
    ],
    sidebarMenu: [
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-message-circle',
          isVisible: [ true ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'chats',
              path: '/',
              component: (): any => import("@/views/chats.vue"),
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-eye-off',
          isVisible: [ () => {
            return store.getters.hasHiddenChats
          } ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'hidden_chats',
              path: '/hidden',
              component: (): any => import("@/views/hidden-chats.vue"),
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-layout',
          isVisible: [ () => store.getters.hasAtLeastOneAdvanced ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'boards',
              path: '/boards',
              component: (): any => import("@/views/posts-planner/views/boards.vue"),
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-calendar',
          isVisible: [
            (ctx) => !!ctx.route.params.boardId,
            () => store.getters.hasAtLeastOneAdvanced
          ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'posts_planner',
              path: '/board/:boardId/posts-planner',
              component: (): any => import("@/views/posts-planner/views/posts-planner.vue"),
              meta: {
                breadcrumbs: (ctx) => [
                  ...baseBoardBreadcrumbs(ctx.route),
                  { label: i18n.t('menu_posts_planner_title') },
                ]
              }
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-layers',
          isVisible: [ (ctx) => !!ctx.route.params.boardId ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'board_styles',
              path: '/board/:boardId/styles',
              component: (): any => import("@/views/posts-planner/views/board-styles-list.vue"),
              meta: {
                breadcrumbs: (ctx) => [
                  ...baseBoardBreadcrumbs(ctx.route),
                  { label: i18n.t('menu_board_styles_title') },
                ]
              }
            },
          },
          class: [],
          tag: (): string => {
            const boardId = router.app.$route.params.boardId

            const currentBoard: Board | undefined = store.state.postsPlanner.boards.find((board:Board) => board.board.token_id.toString() === boardId)

            if (currentBoard && (currentBoard as Board).board.token_code === 'default') {
              return 'Brand'
            }
            return ''
          },
          tagColor: (): string => 'rgb(177, 69, 250)'
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-layers',
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'style',
              path: '/board/:boardId/styles/:actionType',
              component: (): any => import("@/views/posts-planner/views/style-page.vue"),
              meta: {
                breadcrumbs: (ctx) => {
                  return [
                    ...baseBoardBreadcrumbs(ctx.route),
                    { label: i18n.t('menu_board_styles_title') },
                    ...ctx.route.query.styleId ? [ { label: ctx.route.query.styleId.toString().split('.')[0] } ] : [],
                    { label: actionTypeBreadcrumb(ctx) }
                  ]
                }
              }
              },
          },
          class: [],
          tag: (): string => {
            const boardId = router.app.$route.params.boardId
            const currentBoard: any = store.state.postsPlanner.boards.find(board => (board as any).board.token_id.toString() === boardId)
            if (currentBoard.board!.token_code === 'default') {
              return 'Brand'
            }
            return ''
          },
          tagColor: (): string => 'rgb(177, 69, 250)'
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-layout',
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'post',
              path: '/board/:boardId/post/:actionType',
              component: (): any => import("@/views/posts-planner/views/post.vue"),
              meta: {
                breadcrumbs: (ctx) => {
                  return [
                    ...baseBoardBreadcrumbs(ctx.route),
                    { label: i18n.t('menu_posts_planner_title') },
                    { label: actionTypeBreadcrumb(ctx) }
                  ]
                }
              }
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-file-text',
          isVisible: [ (ctx) => !!ctx.route.params.boardId ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'drafts',
              path: '/board/:boardId/drafts/',
              component: (): any => import("@/views/posts-planner/views/drafts-list.vue"),
              meta: {
                breadcrumbs: (ctx) => [
                  ...baseBoardBreadcrumbs(ctx.route),
                  { label: i18n.t('menu_drafts_title') },
                ]
              }
            },
          },
          class: [],
          tag: (): string => {
            const boardId = router.app.$route.params.boardId
            const currentBoard: any = store.state.postsPlanner.boards.find(board => (board as any).board.token_id.toString() === boardId)

            if (currentBoard.board!.token_code === 'default') {
              return 'Brand'
            }
            return ''
          },
          tagColor: () => 'rgb(177, 69, 250)',
        },
      },
      {
        menuItem: {
          type: MenuItemType.DynamicHeader,
          isVisible: [
            (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID],
          ],
          header: (ctx) => ( store.state.chatState.chat as any).group_title,
          avatar: (ctx) => ( store.state.chatState.chat as any).group_photo,
          class: [],
          to: { name: 'dashboard' }
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          isVisible: [
            (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID],
          ],
          featherIcon: true,
          icon: 'icon-shopping-bag',
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'buy',
              path: `/chat/:${ EntityTypes.CHAT_ID }/buy/`,
              component: (): any => import('@/views/buy-chat-license.vue'),
              meta: {
                breadcrumbs: (ctx) => [
                  ...baseChatBreadcrumbs(),
                  { label: i18n.t('nav_buy_title') },
                ]
              }
            },
          },
          class: [ 'vu-nav-license' ],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Divider
        }
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-monitor',
          isVisible: [
            (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID]
          ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'dashboard',
              path: `/chat/:${ EntityTypes.CHAT_ID }/`,
              component: (): any => import("@/views/chat/dashboard/dashboard.vue"),
              meta: {
                breadcrumbs: () => {
                  return [
                      ...baseChatBreadcrumbs(),
                    { label: i18n.t('menu_dashboard_title') }
                  ]
                }
              }
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.DropDown,
          guid: Guid.create().toString(),
          isVisible: [
            (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID]
          ],
          featherIcon: true,
          icon: 'icon-settings',
          name: 'Chat_settings',
          submenu: [
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [
                  (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID]
                ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'users_rights',
                    path: `/chat/:${ EntityTypes.CHAT_ID }/users-rights`,
                    component: (): any => import("@/views/chat/users-rights.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('nav_bar_settings_tab_title') },
                        { label: i18n.t('menu_users_rights_title') }
                      ]
                    }
                  },
                },
                class: [],
              },
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [
                  (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID]
                ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'greeting',
                    path: `/chat/:${ EntityTypes.CHAT_ID }/greeting/`,
                    component: (): any => import("@/views/chat/greeting.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('nav_bar_settings_tab_title') },
                        { label: i18n.t('menu_greeting_title') }
                      ]
                    }
                  },
                },
                class: [],
              },
            },
            {
              menuItem: {
                type: MenuItemType.DropDown,
                guid: Guid.create().toString(),
                isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
                name: 'Tab_moderation',
                selected: [ (ctx) => ctx.route.name === 'filter' ],
                submenu: [
                  {
                    menuItem: {
                      type: MenuItemType.Route,
                      isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
                      routeConfig: {
                        layout: Layout.Panel,
                        config: {
                          name: 'chat_rules',
                          path: `/chat/:${ EntityTypes.CHAT_ID }/chat-rules`,
                          component: (): any => import("@/views/chat/chat-rules.vue"),
                          meta: {
                            breadcrumbs: () => {
                              return [
                                ...baseChatBreadcrumbs(),
                                { label: i18n.t('nav_bar_settings_tab_title') },
                                { label: i18n.t('menu_tab_moderation_title') },
                                { label: i18n.t('menu_chat_rules_title') },
                              ]
                            }
                          }
                        },
                      },
                      class: [],
                    },
                  },
                  {
                    menuItem: {
                      type: MenuItemType.Route,
                      isVisible: [
                        (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID]
                      ],
                      routeConfig: {
                        layout: Layout.Panel,
                        config: {
                          name: 'report_system',
                          path: `/chat/:${ EntityTypes.CHAT_ID }/report-system`,
                          component: (): any => import("@/views/chat/report-system.vue"),
                          meta: {
                            breadcrumbs: () => [
                              ...baseChatBreadcrumbs(),
                              { label: i18n.t('nav_bar_settings_tab_title') },
                              { label: i18n.t('menu_tab_moderation_title') },
                              { label: i18n.t('menu_report_system_title') },
                            ]
                          }
                        },
                      },
                      class: [],
                    },
                  },
                  {
                    menuItem: {
                      type: MenuItemType.Route,
                      isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
                      routeConfig: {
                        layout: Layout.Panel,
                        config: {
                          name: 'face_control_page',
                          path: `/chat/:${ EntityTypes.CHAT_ID }/face-control`,
                          component: (): any => import("@/views/chat/face-control-page.vue"),
                          meta:{
                            breadcrumbs: () => [
                              ...baseChatBreadcrumbs(),
                              { label: i18n.t('nav_bar_settings_tab_title') },
                              { label: i18n.t('menu_tab_moderation_title') },
                              { label: i18n.t('menu_face_control_page_title') },
                            ]
                          }
                        },
                      },
                      class: [],
                    },
                  },
                  {
                    menuItem: {
                      type: MenuItemType.Route,
                      isVisible: [
                        (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID]
                      ],
                      routeConfig: {
                        layout: Layout.Panel,
                        config: {
                          name: 'filters',
                          path: `/chat/:${ EntityTypes.CHAT_ID }/filters`,
                          component: (): any => import("@/views/chat/filters/filters.vue"),
                          meta:{
                            breadcrumbs: () => [
                              ...baseChatBreadcrumbs(),
                              { label: i18n.t('nav_bar_settings_tab_title') },
                              { label: i18n.t('menu_tab_moderation_title') },
                              { label: i18n.t('menu_filters_title') },
                            ]
                          }
                          },
                      },
                      class: [],
                    },
                  },
                  {
                    menuItem: {
                      type: MenuItemType.Route,
                      isVisible: [ (ctx) => false ],
                      routeConfig: {
                        layout: Layout.Panel,
                        config: {
                          name: 'filter',
                          path: `/chat/:${ EntityTypes.CHAT_ID }/filter-setup/:filterName`,
                          component: (): any => import("@/views/chat/filters/filter-page.vue"),
                          meta: {
                            breadcrumbs: (ctx) => [
                              ...baseChatBreadcrumbs(),
                              { label: i18n.t('nav_bar_settings_tab_title') },
                              { label: i18n.t('menu_tab_moderation_title') },
                              { label: i18n.t('menu_filters_title'), routeName: 'filters' },
                              { label: i18n.t(`filter_${ ctx.route.params.filterName }_title`) }
                            ]
                          }
                          },
                      },
                      class: [],
                    },
                  },
                  {
                    menuItem: {
                      type: MenuItemType.Route,
                      isVisible: [ true ],
                      routeConfig: {
                        layout: Layout.Panel,
                        config: {
                          name: 'manual_moderation',
                          path: `/chat/:${ EntityTypes.CHAT_ID }/manual-moderation`,
                          component: (): any => import("@/views/chat/moderation-commands.vue"),
                          meta: {
                            breadcrumbs: () => [
                              ...baseChatBreadcrumbs(),
                              { label: i18n.t('nav_bar_settings_tab_title') },
                              { label: i18n.t('menu_tab_moderation_title') },
                              { label: i18n.t('menu_manual_moderation_title') },
                            ]
                          }
                        },
                      },
                      class: [],
                    },
                  },
                  {
                    menuItem: {
                      type: MenuItemType.Route,
                      isVisible: [ true ],
                      routeConfig: {
                        layout: Layout.Panel,
                        config: {
                          name: 'warning_system',
                          path: `/chat/:${ EntityTypes.CHAT_ID }/warning-system`,
                          component: (): any => import("@/views/chat/filters/warning-system.vue"),
                          meta: {
                            breadcrumbs: () => [
                              ...baseChatBreadcrumbs(),
                              { label: i18n.t('nav_bar_settings_tab_title') },
                              { label: i18n.t('menu_tab_moderation_title') },
                              { label: i18n.t('menu_warning_system_title') },
                            ]
                          }
                        },
                      },
                      class: [],
                    },
                  },
                  {
                    menuItem: {
                      type: MenuItemType.Route,
                      isVisible: [ true ],
                      routeConfig: {
                        layout: Layout.Panel,
                        config: {
                          name: 'moderation_extra',
                          path: `/chat/:${ EntityTypes.CHAT_ID }/moderation/extra`,
                          component: (): any => import("@/views/chat/moderation/extra.vue"),
                          meta: {
                            breadcrumbs: () => [
                              ...baseChatBreadcrumbs(),
                              { label: i18n.t('nav_bar_settings_tab_title') },
                              { label: i18n.t('menu_tab_moderation_title') },
                              { label: i18n.t('menu_moderation_extra_title') },
                            ]
                          }
                        },
                      },
                      class: [],
                    },
                  },
                ]
              },
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [ true ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'chat_activity_support',
                    path: `/chat/:${ EntityTypes.CHAT_ID }/chat-activity-support`,
                    component: (): any => import("@/views/chat/chat-activity-support.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('nav_bar_settings_tab_title') },
                        { label: i18n.t('menu_chat_activity_support_title') },
                      ]
                    }
                  },
                },
                class: [],
              },
            },
            {
              menuItem: {
                type: MenuItemType.DropDown,
                guid: Guid.create().toString(),
                isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
                name: 'tab_reputation',
                submenu: [
                  {
                    menuItem: {
                      type: MenuItemType.Route,
                      isVisible: [
                        (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID]
                      ],
                      routeConfig: {
                        layout: Layout.Panel,
                        config: {
                          name: 'reputation',
                          path: `/chat/:${ EntityTypes.CHAT_ID }/reputation`,
                          component: (): any => import("@/views/chat/reputation/reputation.vue"),
                          meta: {
                            breadcrumbs: () => [
                              ...baseChatBreadcrumbs(),
                              { label: i18n.t('nav_bar_settings_tab_title') },
                              { label: i18n.t('menu_tab_reputation_title') },
                              { label: i18n.t('menu_reputation_title') },
                            ]
                          }
                        },
                      },
                      class: [],
                    },
                  },
                  {
                    menuItem: {
                      type: MenuItemType.Route,
                      isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
                      routeConfig: {
                        layout: Layout.Panel,
                        config: {
                          name: 'reputation_extended',
                          path: `/chat/:${ EntityTypes.CHAT_ID }/reputation-extended`,
                          component: (): any => import("@/views/chat/reputation/reputation-extended.vue"),
                          meta: {
                            breadcrumbs: () => [
                              ...baseChatBreadcrumbs(),
                              { label: i18n.t('nav_bar_settings_tab_title') },
                              { label: i18n.t('menu_tab_reputation_title') },
                              { label: i18n.t('menu_reputation_extended_title') },
                            ]
                          }
                        },
                      },
                      tag: () => {
                        if (!(store.state.chatState.chat as any).limits.options.includes('extended_reputation')) {
                          return i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('extended_reputation'))).toString()
                        }

                        return ''
                      },
                      tagColor: () => {
                        return store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('extended_reputation')))).color
                      },
                      class: [],
                    },
                  },
                  {
                    menuItem: {
                      type: MenuItemType.Route,
                      isVisible: [
                        (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID]
                      ],
                      routeConfig: {
                        layout: Layout.Panel,
                        config: {
                          name: 'reputation_extended_xp',
                          path: `/chat/:${ EntityTypes.CHAT_ID }/reputation-extended-xp`,
                          component: (): any => import("@/views/chat/reputation/reputation-extended-xp.vue"),
                          meta: {
                            breadcrumbs: () => [
                              ...baseChatBreadcrumbs(),
                              { label: i18n.t('nav_bar_settings_tab_title') },
                              { label: i18n.t('menu_tab_reputation_title') },
                              { label: i18n.t('menu_reputation_extended_xp_title') },
                            ]
                          }
                        },
                      },
                      tag: () => {
                        if (!(store.state.chatState.chat as any).limits.options.includes('extended_reputation')) {
                          return i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('extended_reputation'))).toString()
                        }

                        return ''
                      },
                      tagColor: () => {
                        return store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('extended_reputation')))).color
                      },
                      class: [],
                    },
                  },
                  {
                    menuItem: {
                      type: MenuItemType.Route,
                      isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
                      routeConfig: {
                        layout: Layout.Panel,
                        config: {
                          name: 'reputation_extended_triggers',
                          path: `/chat/:${ EntityTypes.CHAT_ID }/reputation-extended-triggers`,
                          component: (): any => import("@/views/chat/reputation/reputation-extended-triggers.vue"),
                          meta: {
                            breadcrumbs: () => [
                              ...baseChatBreadcrumbs(),
                              { label: i18n.t('nav_bar_settings_tab_title') },
                              { label: i18n.t('menu_tab_reputation_title') },
                              { label: i18n.t('menu_reputation_extended_triggers_title') },
                            ]
                          }
                        },
                      },
                      tag: () => {
                        if (!(store.state.chatState.chat as any).limits.options.includes('extended_reputation')) {
                          return i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('extended_reputation'))).toString()
                        }

                        return ''
                      },
                      tagColor: () => {
                        return store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('extended_reputation')))).color
                      },
                      class: [],
                    },
                  },
                  {
                    menuItem: {
                      type: MenuItemType.Route,
                      isVisible: [
                        (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID]
                      ],
                      routeConfig: {
                        layout: Layout.Panel,
                        config: {
                          name: 'top_message_config',
                          path: `/chat/:${ EntityTypes.CHAT_ID }/top-message-config`,
                          component: (): any => import("@/views/chat/top-message-config.vue"),
                          meta: {
                            breadcrumbs: () => [
                              ...baseChatBreadcrumbs(),
                              { label: i18n.t('nav_bar_settings_tab_title') },
                              { label: i18n.t('menu_tab_reputation_title') },
                              { label: i18n.t('menu_top_message_config_title') },
                            ]
                          }
                        },
                      },
                      tag: () => {
                        if (!(store.state.chatState.chat as any).limits.options.includes('custom_tops')) {
                          return i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('custom_tops'))).toString()
                        }

                        return ''
                      },
                      tagColor: () => {
                        return store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('custom_tops')))).color
                      },
                      class: [],
                    },
                  },
                  {
                    menuItem: {
                      type: MenuItemType.Route,
                      isVisible: [
                        (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID]
                      ],
                      routeConfig: {
                        layout: Layout.Panel,
                        config: {
                          name: 'rank_system',
                          path: `/chat/:${ EntityTypes.CHAT_ID }/rank-system`,
                          component: (): any => import("@/views/chat/reputation/rank-system.vue"),
                          meta: {
                            breadcrumbs: () => [
                              ...baseChatBreadcrumbs(),
                              { label: i18n.t('nav_bar_settings_tab_title') },
                              { label: i18n.t('menu_tab_reputation_title') },
                              { label: i18n.t('menu_rank_system_title') },
                            ]
                          }
                        },
                      },
                      tag: () => {
                        if (!(store.state.chatState.chat as any).limits.options.includes('extended_reputation')) {
                          return i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('extended_reputation'))).toString()
                        }

                        return ''
                      },
                      tagColor: () => {
                        return store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('extended_reputation')))).color
                      },
                      class: [],
                    },
                  },

                ],
              },
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [
                  (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID]
                ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'journal',
                    path: `/chat/:${ EntityTypes.CHAT_ID }/journal`,
                    component: (): any => import("@/views/chat/journal/journal.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('nav_bar_settings_tab_title') },
                        { label: i18n.t('menu_journal_title') },
                      ]
                    }
                  },
                },
                class: [],
              },
            }, {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [
                  (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] && ( store.state.chatState.chat as any).version >= 31000
                ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'chat_properties',
                    path: `/chat/:${ EntityTypes.CHAT_ID }/properties`,
                    component: (): any => import("@/views/chat/chat-properties.vue"),
                  },
                },
                tag: () => {
                  if (!(store.state.chatState.chat as any).limits.options.includes('chat_properties')) {
                    return i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('chat_properties'))).toString()
                  }

                  return ''
                },
                tagColor: () => {
                  return store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('chat_properties')))).color
                },
                class: [],
              },
            },
          ],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Divider
        }
      },
      {
        menuItem: {
          featherIcon: true,
          icon: 'icon-tag',
          type: MenuItemType.Route,
          isVisible: [
            (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID]
          ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'Branding',
              path: `/chat/:${ EntityTypes.CHAT_ID }/branding`,
              component: (): any => import("@/views/chat/branding.vue"),
              meta: {
                breadcrumbs: () => [
                  ...baseChatBreadcrumbs(),
                  { label: i18n.t('menu_branding_title') },
                ]
              }
            },
          },
          class: [],
          tag: () => {
            if (!(store.state.chatState.chat as any).limits.options.includes('branding')) {
              return i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('branding'))).toString()
            }

            return ''
          },
          tagColor: () => {
            return store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('branding')))).color
          }
        },
      },
      {
        menuItem: {
          featherIcon: true,
          icon: 'icon-zap',
          type: MenuItemType.Route,
          isVisible: [
            (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID]
          ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'chat_triggers',
              path: `/chat/:${ EntityTypes.CHAT_ID }/triggers`,
              component: (): any => import("@/views/chat/triggers.vue"),
              meta: {
                breadcrumbs: () => [
                  ...baseChatBreadcrumbs(),
                  { label: i18n.t('menu_chat_triggers_title') },
                ]
              }
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          featherIcon: true,
          icon: 'icon-zap',
          type: MenuItemType.Route,
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'chat_trigger',
              path: `/chat/:${ EntityTypes.CHAT_ID }/trigger/:actionType/`,
              component: (): any => import("@/views/chat/trigger.vue"),
              meta: {
                breadcrumbs: (ctx) => {
                  return [
                    ...baseChatBreadcrumbs(),
                    { label: i18n.t('menu_chat_triggers_title'), routeName: 'chat_triggers' },
                      ...ctx.route.query.triggerId ? [ { label: ctx.route.query.triggerId.toString().split('.')[0] } ] : [],
                    { label: actionTypeBreadcrumb(ctx) }
                  ]
                }
              }
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          featherIcon: true,
          icon: 'icon-clipboard',
          type: MenuItemType.Route,
          isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'questionnaire',
              path: `/chat/:${ EntityTypes.CHAT_ID }/questionnaire/`,
              component: (): any => import("@/views/chat/questionnaire/questionnaire.vue"),
              meta: {
                breadcrumbs: () => [
                  ...baseChatBreadcrumbs(),
                  { label: i18n.t('menu_questionnaire_title') }
                ]
              }
            },
          },
          class: [],
          tag: () => {
            if (!(store.state.chatState.chat as any).limits.options.includes('forms')) {
              return i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('forms'))).toString()
            }
            return ''
          },
          tagColor: () => {
            return store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('forms')))).color
          }
        }
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Fullscreen,
            config: {
              name: 'Form',
              path: "/form/:key/",
              component: (): any => import("@/views/form/form.vue"),
            },
          },
          class: [],
        }
      },
      {
        menuItem: {
          featherIcon: true,
          icon: 'icon-calendar',
          type: MenuItemType.Route,
          isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'posts_planner',
              path: '/board/:boardId/posts-planner',
              params: () => {
                return {
                  boardId: ( store.state.chatState.chat as any).post_board.token_id
                }
              },
              component: (): any => import("@/views/posts-planner/views/posts-planner.vue"),
            },
          },
          class: [],
        }
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'questionnaire_form',
              path: `/chat/:${ EntityTypes.CHAT_ID }/questionnaire/form/:actionType/`,
              component: (): any => import("@/views/chat/questionnaire/questionnaire-form.vue"),
              meta: {
                breadcrumbs: (ctx) => {
                  return [
                    ...baseChatBreadcrumbs(),
                    { label: i18n.t('menu_questionnaire_title'), routeName: 'questionnaire' },
                    ...ctx.route.query.formGuid ? [ { label: ctx.route.query.formGuid.toString().split('.')[0] } ] : [],
                    { label: actionTypeBreadcrumb(ctx) }
                  ]
                }
              }
            },
          },
          class: [],
        }
      },
      {
        menuItem: {
          type: MenuItemType.Divider
        }
      },
      {
        menuItem: {
          type: MenuItemType.DropDown,
          featherIcon: true,
          icon: 'icon-link',
          guid: Guid.create().toString(),
          isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
          name: 'tab_invite_links',
          submenu: [
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'invite_links_list',
                    path: `chat/:${ EntityTypes.CHAT_ID }/link-list`,
                    component: (): any => import("@/views/chat/invite-links/link-list.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_invite_links_list_title') }
                      ]
                    }
                  },
                },
                class: [],
              }
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'invite_links_statistics',
                    path: `chat/:${ EntityTypes.CHAT_ID }/link-statistics/:linkCode?`,
                    component: (): any => import("@/views/chat/invite-links/link-statistics.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_invite_links_list_title'), routeName: 'invite_links_list' },
                        { label: i18n.t('menu_invite_links_statistics_title') }
                      ]
                    }
                  },
                },
                class: [],
              }
            },
          ]
        },
      },
      {
        menuItem: {
          type: MenuItemType.DropDown,
          guid: Guid.create().toString(),
          featherIcon: true,
          icon: 'icon-bar-chart',
          isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
          name: 'tab_chat_statistics',
          submenu: [
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'statistics',
                    path: `chat/:${ EntityTypes.CHAT_ID }/statistics`,
                    component: (): any => import("@/views/chat/statistics/statistics.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_tab_chat_statistics_title'), },
                        { label: i18n.t('menu_statistics_title'), },
                      ]
                    }
                  },
                },
                class: [],
              }
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'statistics_users',
                    path: `chat/:${ EntityTypes.CHAT_ID }/statistics/users`,
                    component: (): any => import("@/views/chat/statistics/statistics-users.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_tab_chat_statistics_title'), },
                        { label: i18n.t('menu_statistics_users_title'), },
                      ]
                    }
                  },
                },
                tag: () => {
                  if (!(store.state.chatState.chat as any).limits.options.includes('stat_users')) {
                    return i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('stat_users'))).toString()
                  }

                  return ''
                },
                tagColor: () => {
                  return store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('stat_users')))).color
                },
                class: [],
              }
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'statistics_users_analysis',
                    path: `chat/:${ EntityTypes.CHAT_ID }/statistics/users-analysis`,
                    component: (): any => import("@/views/chat/statistics/statistics-users-analysis.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_tab_chat_statistics_title'), },
                        { label: i18n.t('menu_statistics_users_analysis_title'), },
                      ]
                    }
                  },
                },
                tag: () => {
                  if (!(store.state.chatState.chat as any).limits.options.includes('statistic_users_analysis')) {
                    return i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('statistic_users_analysis'))).toString()
                  }

                  return ''
                },
                tagColor: () => {
                  return store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('statistic_users_analysis')))).color
                },
                class: [],
              }
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'statistics_referrals',
                    path: `chat/:${ EntityTypes.CHAT_ID }/statistics/referrals`,
                    component: (): any => import("@/views/chat/statistics/statistics-referrals.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_tab_chat_statistics_title'), },
                        { label: i18n.t('menu_statistics_referrals_title'), },
                      ]
                    }
                    },
                },
                class: [],
                tag: () => {
                  if (!(store.state.chatState.chat as any).limits.options.includes('stat_referrals')) {
                    return i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('stat_referrals'))).toString()
                  }
                  return ''
                },
                tagColor: () => {
                  return store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('stat_referrals')))).color
                }
              }
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'reputation_chart',
                    path: `chat/:${ EntityTypes.CHAT_ID }/reputation/chart`,
                    component: (): any => import("@/views/chat/reputation/reputation-chart.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_tab_chat_statistics_title'), },
                        { label: i18n.t('menu_reputation_chart_title'), },
                      ]
                    }
                  },
                },
                class: [],
              }
            },
          ]
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          icon: 'icon-book',
          featherIcon: true,
          isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'Journal_log',
              path: `chat/:${ EntityTypes.CHAT_ID }/journal/log`,
              component: (): any => import("@/views/chat/journal/journal-log.vue"),
              meta: {
                breadcrumbs: () => [
                  ...baseChatBreadcrumbs(),
                  { label: i18n.t('menu_journal_log_title'), },
                ]
              }
            },
          },
          class: [],
          tag: () => {
            if (!(store.state.chatState.chat as any).limits.options.includes('site_journal')) {
              return i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('site_journal'))).toString()
            }
            return ''
          },
          tagColor: () => {
            return store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('site_journal')))).color
          }
        }
      },
      {
        menuItem: {
          type: MenuItemType.Divider
        }
      },
      {
        menuItem: {
          type: MenuItemType.DropDown,
          guid: Guid.create().toString(),
          featherIcon: true,
          icon: 'icon-more-horizontal',
          isVisible: [
            (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID],
          ],
          name: 'tab_other',
          submenu: [
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [
                  (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID],
                  // (_) => store.state.chatState.chat && (store.state.chatState.chat as any).config.anchors.length > 0
                ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'anchors',
                    path: `chat/:${ EntityTypes.CHAT_ID }/anchors`,
                    component: (): any => import("@/views/chat/anchors.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_tab_other_title'), },
                        { label: i18n.t('menu_anchors_title'), },
                      ]
                    }
                  },
                },
                class: [],
                tag: () => {
                  if (!(store.state.chatState.chat as any).limits.options.includes('anchors')) {
                    return i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('anchors'))).toString()
                  }
                  return ''
                },
                tagColor: () => {
                  return store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('anchors')))).color
                }

              }
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'referral_system',
                    path: `chat/:${ EntityTypes.CHAT_ID }/referral-system`,
                    component: (): any => import("@/views/chat/referral-system.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_tab_other_title'), },
                        { label: i18n.t('menu_referral_system_title'), },
                      ]
                    }
                  },
                },
                class: [],
                tag: () => {
                  if (!(store.state.chatState.chat as any).limits.options.includes('referral_system')) {
                    return i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('referral_system'))).toString()
                  }
                  return ''
                },
                tagColor: () => {
                  return store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('referral_system')))).color
                }

              }
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'external_integrations',
                    path: `chat/:${ EntityTypes.CHAT_ID }/external-integrations`,
                    component: (): any => import("@/views/chat/external-integrations.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_tab_other_title'), },
                        { label: i18n.t('menu_external_integrations_title'), },
                      ]
                    }
                  },
                },
                class: [],
                tag: () => {
                  if (!(store.state.chatState.chat as any).limits.options.includes('integrations')) {
                    return i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('integrations'))).toString()
                  }
                  return ''
                },
                tagColor: () => {
                  return store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('integrations')))).color
                }
              }
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'extra',
                    path: `chat/:${ EntityTypes.CHAT_ID }/extra`,
                    component: (): any => import("@/views/chat/extra.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_tab_other_title'), },
                        { label: i18n.t('menu_extra_title'), },
                      ]
                    }
                    },
                },
                class: [],
              }
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [ (ctx) => !!ctx.route.params[EntityTypes.CHAT_ID] ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'catalog_integration',
                    path: `chat/:${ EntityTypes.CHAT_ID }/catalog-integration`,
                    component: (): any => import("@/views/chat/catalog-integration.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_tab_other_title'), },
                        { label: i18n.t('menu_catalog_integration_title'), },
                      ]
                    }
                    },
                },
                class: [],
              }
            },
          ]
        },
      },
      /******** TODO CHECK LATER PAYMENTS START********/
      {
        menuItem: {
          type: MenuItemType.Route,
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Fullscreen,
            config: {
              name: 'Payment',
              path: "/payment/:type/:key/",
              component: (): any => import("@/views/payment/payment.vue"),
            },
          },
          class: [],
        }
      },
      /******** TODO CHECK LATER PAYMENTS END********/
      {
        menuItem: {
          type: MenuItemType.Route,
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Fullscreen,
            config: {
              name: 'Choose_chat',
              path: "/buy-license/choose-chat/",
              component: (): any => import("@/views/buy-license/choose-chat.vue"),
            },
          },
          class: [],
        }
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Fullscreen,
            config: {
              name: 'start_add_chat',
              path: "/add-chat/:key?",
              component: (): any => import("@/views/add-chat.vue"),
            },
          },
          class: [],
        }
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'user_info',
              path: `/chat/:${ EntityTypes.CHAT_ID }/user-info/:userId`,
              component: (): any => import("@/views/chat/user-info.vue"),
            },
          },
          class: [],
        }
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'Wizard-page',
              path: `/chat/:${ EntityTypes.CHAT_ID }/wizard-page`,
              component: () => import("@/views/wizard-page.vue"),
            },
          },
          class: [],
        }
      },
    ],
    globalGuards: {
      afterEach: (to) => {
        if (!to.params[EntityTypes.CHAT_ID]) {
          store.commit("updateChat", {});
          store.commit("resetFrontendTriggers");
          store.commit("updatedTriggerId", null);

          document.title = `${ process.env.VUE_APP_TARGET_PROJECT }.app`
        }

        if (to.name === 'boards' || to.params.boardId || !to.params[EntityTypes.CHAT_ID]) {
          setTimeout(() => {
            Vue.prototype.$baseTemplate.saveButton.hide()
          }, 0)
        }
      },
      beforeEach: async (to: Route, next: NavigationGuardNext): Promise<void> => {
        const promises = [];
        const chatData: any = store.state.chatState.chat;

          if (Number.parseInt(to.params[EntityTypes.CHAT_ID])) {
            if (chatData.chat_id === undefined || parseInt(chatData.chat_id) !== parseInt(to.params[EntityTypes.CHAT_ID])) {
              promises.push(store.dispatch("requestChatConfig", {
                id: to.params[EntityTypes.CHAT_ID],
                vm: Vue.prototype,
              }))
            }
          }

          if (to.path.includes('/boards') || to.params.boardId) {
            if (isEmpty(store.state.postsPlanner.boards)) {
              promises.push(store.dispatch("requestPostBoards"))
            }
          }

          if (!(store.state.chatState.chatsInfo as any)?.chats || to.name === 'chats') {
            promises.push(store.dispatch("requestChatsInfo", Vue))
          }

          Promise.all(promises)
              .then(res => {
                document.title = `${ (store.state.chatState.chat as any).group_title } (${ (store.state.chatState.chat as any).chat_id })`
              })
              .finally(() => next())

        },
    },
    onSaveButtonClick: (ctx, wasClicked): void => {
      if (wasClicked) {
        if (ctx.route.params[EntityTypes.CHAT_ID])
          store.dispatch('saveChatConfig')
      }
    }
  },
  router: router,
  store: store,
}

Vue.use(AdminTemplate as unknown as PluginFunction<any>, adminTemplateOptions)

Vue.config.productionTip = false;

(window as any).mobilecheck = isMobile;

export default new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount("#app");
