import Api                     from "../../includes/logic/Api";
import VuTriggers              from "@/includes/logic/VuTriggers";
import TriggerStatDataAdapter  from "@/includes/logic/TriggerStatDataAdapter";
import DashboardStatAdapter      from "@/includes/logic/DashboardStatAdapter";
import NewDashboardStatAdapter   from "@/includes/logic/dashboard/DashboardStatAdapter";
import NewTriggerStatDataAdapter from "@/includes/logic/dashboard/TriggerStatDataAdapter";

export default {
  actions: {
    GET_NEW_DASHBOARD_STAT(ctx, { chat_id, isFull, }) {
      return new Promise((resolve, reject) => {
	let statisticsRequest;

	if(isFull) {
	  statisticsRequest = Api.getDashboardFullStat("tg", { chat_id });
	} else {
	  statisticsRequest = Api.getDashboardStat("tg", { chat_id });
	}
	statisticsRequest.then(res => {
	  resolve(new NewDashboardStatAdapter(res.data).getData());
	}).catch(reject);
      });
    },
    GET_NEW_CHAT_TRIGGERS_STAT(ctx, { chat_id, triggers }) {
      return new Promise((resolve, reject) => {
	Api.getChatTriggersStat("tg", { chat_id })
	   .then(res => {
	     VuTriggers.updateTriggers()
		  .then(() => {
		    resolve(new NewTriggerStatDataAdapter(res.data, triggers.triggers).getData());
		  });
	   })
	   .catch(reject);
      });
    },
    GET_DASHBOARD_STAT(ctx, {
      chat_id,
      $i18n,
      isFull,
    }) {
      return new Promise((resolve, reject) => {
	let statisticsRequest;

	if(isFull) {
	  statisticsRequest = Api.getDashboardFullStat("tg", { chat_id });
	} else {
	  statisticsRequest = Api.getDashboardStat("tg", { chat_id });
	}
	statisticsRequest.then(res => {
	  const dashboardStatAdapter = new DashboardStatAdapter(res.data, res.data.period_start, res.data.period_end, $i18n);
	  resolve(dashboardStatAdapter.getData());
	}).catch(reject);
      });
    },
    GET_CHAT_TRIGGERS_STAT(ctx, { chat_id }) {
      return new Promise((resolve, reject) => {
	Api.getChatTriggersStat("tg", { chat_id })
	   .then(res => {
	     const tmpDataObject = {};
	     res.data.triggers_stat.forEach(statItem => {
	       if(tmpDataObject[ statItem.trigger ] === undefined) {
		 tmpDataObject[ statItem.trigger ] = [];
	       }
	       tmpDataObject[ statItem.trigger ].push(statItem);
	     });

	     VuTriggers
		  .updateTriggers()
		  .then(() => {
		    for(let key in tmpDataObject) {
		      if(tmpDataObject.hasOwnProperty(key)) {
			const triggerStatAdapter = new TriggerStatDataAdapter(key, tmpDataObject[ key ], res.data.period_start, res.data.period_end);
			tmpDataObject[ key ]     = triggerStatAdapter.getDataFormattedForApexChartsStandard(VuTriggers.getTriggerFormVuex(key));
		      }
		    }
		    resolve(Object.values(tmpDataObject));
		  });
	   })
	   .catch(reject);
      });
    },
  },
  getters: {},
};
