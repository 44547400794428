import Vue                                      from "vue";
import i18n                                     from "@/i18n/i18n";
import Api                                      from "../../../includes/logic/Api";
import store                                    from "@/store/store";
import { successNotification, errorNotification } from "@/includes/NotificationService";

export default {
  /*
   --- Action List ---
   Включить тихий режим - enablechatsilentforusers/disablechatsilentforusers (silent_for_users)
   Включить тихий режим для всех - enablechatsilentforall/disablechatsilentforall (silent_for_all)
   Добавить администраторов в бота - addchatadmins
   Сбросить предупреждения - resetchatlimits
   Сбросить репутацию - resetchatrep
   Сбросить тихий режим
   Сбросить до завтодских настроек - resetchatconfig
   Скрыть - hidechat/showchat (hidden)
   Включить - enablechat
   Выключить - disable
   */
  runChatAction({ state, commit }, action) {
    Vue.prototype.$confirm({
      title     : i18n.t("confirm_save_chat_config_title").toString(),
      content   : i18n.t("confirm_chat_action").toString(),
      okText    : i18n.t("accept").toString(),
      cancelText: i18n.t("reject").toString(),
      onOk: () => {
	Vue.prototype.$baseTemplate.loader.open();

	Api.runChatAction("tg", {
	  chat_id: parseInt(state.chatState.chat.chat_id),
	  action,
	})
	   .then((res) => {
	     if(res.data !== undefined) {
	       if(res.data.chat !== undefined) {
		 commit("updateChat", res.data.chat);
	       }
	     }

	     successNotification(i18n.t("done").toString());
	   }).catch(error => {
	  if(error.response !== undefined) {
	    errorNotification(error);
	  }
	}).finally(() => {
	  Vue.prototype.$baseTemplate.loader.close();
	});
      },
    });
  },
  toggleChatEnabled({ state, commit }, { chat_id, action, }) {
    Vue.prototype.$confirm({
      title     : i18n.t("confirm_save_chat_config_title").toString(),
      content   : i18n.t("confirm_chat_action").toString(),
      okText    : i18n.t("accept").toString(),
      cancelText: i18n.t("reject").toString(),
      onOk      : () => {
	Vue.prototype.$baseTemplate.loader.open();

	Api.runChatAction("tg", {
	  chat_id,
	  action,
	})
	   .then(() => {
	     store.dispatch("requestChatsInfo", Vue);

	     if(state.chatState.chat) {
	       commit("setObjectField", {
		 object    : state.chatState.chat,
		 objectPath: "enabled",
		 value     : action === "enablechat",
	       });
	     }

	     successNotification(i18n.t("done").toString());
	   })
	   .catch(error => {
	     if(error.response !== undefined) {
	       errorNotification(error);
	     }
	   })
	   .finally(() => {
	     Vue.prototype.$baseTemplate.loader.close();
	   });
      },
    });
  },
};
