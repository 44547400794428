import Api                 from "@/includes/logic/Api";
import { errorNotification } from "@/includes/NotificationService";

export default {
  requestPostBoards({ commit }) {
    return new Promise(resolve => {
      Api.getPostBoards("tg")
	 .then(({ data }) => {
	   commit("set_boards_to_store", data.boards);
	   resolve(data.boards);
	 })
	 .catch(errorNotification)
    });
  },
};
