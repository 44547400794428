import moment from "moment";
import { cloneDeep } from "lodash";
import camelToUnderscoreCase from "@/assets/utils/camelToUnderscoreCase";
import { ratesKeys, combinedRatesKeys } from './keys'
import { ChartData, combinedRate, combinedRates, Rates, RawDashboardData } from "./types";

export default class NewDashboardStatAdapter {
  _dashboardRawData;
  _dateFrom;
  _dateTo;
  _dateTimesArr: Array<string> = [];

  constructor(dashboardRawData: RawDashboardData) {
    this._dashboardRawData = dashboardRawData;
    this._dateFrom = dashboardRawData.period_start;
    this._dateTo = dashboardRawData.period_end;
    this._getDatesArr();
  }

  _getDatesArr() {
    const from = moment(this._dateFrom);
    const to = moment(this._dateTo);

    while (!from.isAfter(to)) {
      this._dateTimesArr.push(from.format("YYYY-MM-DD"));
      from.add(1, "day");
    }
  }

  _processTimeValueData(fieldKeys: Array<string>) {
    const stats: Array<[string, ChartData]> = [];

    fieldKeys.forEach(str => {
      let rawField: Array<combinedRate> | undefined
      if(combinedRatesKeys.includes(str)) {
        let key = str as keyof combinedRates
       rawField = this._dashboardRawData[key]
      }

      if (ratesKeys.includes(str))  {
        let key = str as keyof Rates
        rawField = this._dashboardRawData.rates[key]
      }

      if (rawField) {
        let field = {
          total: 0,
          series: [
            {
              name: `dashboard_statistics_${ camelToUnderscoreCase(str) }`,
              data: new Array(8).fill(0)
            }
          ],
          labels: cloneDeep(this._dateTimesArr)
        }

        rawField.forEach(dataItem => {
          const dateIndex = field.labels.indexOf(dataItem.time)
          field.total += dataItem.value
          field.series[0].data[dateIndex] = dataItem.value
        })

        stats.push([ str, field ])
      }
    })
    return Object.fromEntries(stats)
  }

  _processFiltersStatistics() {
    const stat = [];

    for (let [ filterKey, filterData ] of Object.entries(this._dashboardRawData.filters)) {
      const data = new Array(8).fill(0);
      for (let [ time, value ] of Object.entries(filterData)) {
        const dateIndex = this._dateTimesArr.indexOf(time)
        data[dateIndex] = value;
      }

      const filter = {
        total: Object.values(filterData).reduce((a, b) => a + b),
        series: [
          {
            name: `${ filterKey }_enabled`,
            data: data
          }
        ],
        labels: this._dateTimesArr.slice()
      }

      stat.push([ filterKey, filter ])
    }

    return Object.fromEntries(stat);
  }

  _processActiveUsersStatistics() {
    const arr = new Array(8).fill(0);

    this._dashboardRawData.active_users.forEach(user => {
      let dateIndex = this._dateTimesArr.indexOf(user.time);
      arr[dateIndex] += 1;
    });

    return {
      series: [
        {
          name: 'dashboard_statistics_active_users',
          data: arr
        }
      ],
      labels: cloneDeep(this._dateTimesArr)
    };
  }

  _processTotalActiveUsersStatistics() {
    let values = Object.values(this._dashboardRawData.total_active_users)
    let entries = Object.entries(this._dashboardRawData.total_active_users)

    if (values.length) {
      return {
        total: values.reduce((a, b) => a + b), // Складывание всех значений в массиве
        users: entries.sort((a, b) => b[1] - a[1]) // Сортировка по убыванию
      }
    } else {
      return null
    }
  }

  _processReputationUsers() {
    let whoGotTimes: { [key: string]: Array<number> } = {}
    let whoGiveTimes: { [key: string]: Array<number> } = {}

    this._dateTimesArr.forEach(time => {
      whoGotTimes[time] = []
      whoGiveTimes[time] = []
    })

    this._dashboardRawData.reputation_users.forEach(event => {
      const time: string = event.time
      const actor: number = event.actor_user_id
      const target: number = event.target_user_id

      if (!whoGotTimes[time].includes(target)) {
        whoGotTimes[time].push(target)
      }
      if (!whoGiveTimes[time].includes(actor)) {
        whoGiveTimes[time].push(actor)
      }
    })

    const whoGotSeries = Object.values(whoGotTimes).map((arr: Array<number>) => arr.length)
    const whoGiveSeries = Object.values(whoGiveTimes).map((arr: Array<number>) => arr.length)

    return {
      series: [
        {
          name: 'dashboard_statistics_reputation_actors',
          data: whoGiveSeries
        },
        {
          name: 'dashboard_statistics_reputation_targets',
          data: whoGotSeries
        }
      ],
      labels: cloneDeep(this._dateTimesArr)
    }
  }

  getData() {
    return {
      combinedRates: this._processTimeValueData(combinedRatesKeys),
      rates: this._dashboardRawData.rates ? this._processTimeValueData(ratesKeys) : null,
      filter: this._processFiltersStatistics(),
      users: {
        active_users: this._processActiveUsersStatistics(),
        total_active_users: this._processTotalActiveUsersStatistics(),
      },
      reputation: {
        reputation_users: this._processReputationUsers(),
      },
    };
  }
}
