import deepMergeObjects from "@/assets/utils/deepMergeObjects";

const mutations = {
	update_placeholders(state, n) {
		state.chatState.placeholders = n; // {}
	},
	update_filter_templates(state, n) {
		state.chatState.filter_templates = n; // {}
	},
	setFilterTemplateConfig(state, payload) {
		 deepMergeObjects(state.chatState.configCopy, payload.config);
	},
	update_runtime_config(state, payload) {
		state.chatState.runtime_config = payload;
	},
};

export default mutations;
