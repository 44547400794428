import authMutations     	from "./auth/mutations";
import chatMutations     	from "./chats/mutations";
import triggersMutations 	from "./triggers/mutations";
import userMutations     	from "./user/mutations";
import postsPlannerMutations 	from "./postsPlanner/mutations";
import flowsMutations		from "./flow/mutations";

const mutations = {
	// UPDATE_BREADCRUMBS(state, newBreadCrumbs) {
	// 	state.breadcrumbs = newBreadCrumbs
	// },
	//
	// // ////////////////////////////////////////////
	// // SIDEBAR & UI UX
	// // ////////////////////////////////////////////
	//
	// UPDATE_SIDEBAR_WIDTH(state, width) {
	// 	state.sidebarWidth = width;
	// },
	// UPDATE_SIDEBAR_ITEMS_MIN(state, val) {
	// 	state.sidebarItemsMin = val;
	// },
	// TOGGLE_REDUCE_BUTTON(state, val) {
	// 	state.reduceButton = val;
	// },
	// TOGGLE_CONTENT_OVERLAY(state, val) {
	// 	state.bodyOverlay = val;
	// },
	// TOGGLE_IS_SIDEBAR_ACTIVE(state, value) {
	// 	state.isSidebarActive = value;
	// },
	// UPDATE_THEME(state, val) {
	// 	state.theme = val;
	// },
	// UPDATE_WINDOW_BREAKPOINT(state, val) {
	// 	state.breakpoint = val;
	// },
	// UPDATE_PRIMARY_COLOR(state, val) {
	// 	state.themePrimaryColor = val;
	// },
	// UPDATE_WINDOW_WIDTH(state, width) {
	// 	state.windowWidth = width;
	// },
	// auth
	...authMutations,
	// chats
	...chatMutations,
	// user
	...userMutations,
	// tiggers
	...triggersMutations,
  	// posts planner
  	...postsPlannerMutations,
  	// flows
  	...flowsMutations
};

export default mutations;
