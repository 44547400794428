export default {
	helloRemove(state) {
		state.chatState.chat.config.hello_remove = +!(+state.chatState.chat.config.hello_remove);
	},
	helloMessage(state, val) {
		state.chatState.chat.config.hello_message = val;
	},
	checkNewUsers(state) {
		state.chatState.chat.config.check_new_users = +!(+state.chatState.chat.config.check_new_users);
	},
	checkNewUsersText(state, val) {
		state.chatState.chat.config.check_new_users_text = val;
	},
	remove_hellow_after_button_pressed(state) {
		state.chatState.chat.config.remove_hellow_after_button_pressed = +!(+state.chatState.chat.config.remove_hellow_after_button_pressed);
	},
	removeHellowButton(state) {
		state.chatState.chat.config.remove_hellow_button = +!(+state.chatState.chat.config.remove_hellow_button);
	},
	removePreviosHellow(state) {
		state.chatState.chat.config.remove_previos_hellow = +!(+state.chatState.chat.config.remove_previos_hellow);
	},
	removeNotConfirmUsers(state, val) {
		state.chatState.chat.config.remove_not_confirm_users = val;
	},
	leaveRemove(state) {
		state.chatState.chat.config.leave_remove = +!(+state.chatState.chat.config.leave_remove);
	},
	leaveMessage(state, val) {
		state.chatState.chat.config.leave_member_message = val;
	},
};
