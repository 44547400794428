import { cloneDeep, forIn, isUndefined, isEmpty }                     from "lodash";
import recursiveCompareObjects from "@/assets/utils/recursiveCompareObjects";
import Api                     from "@/includes/logic/Api";

const actions = {
	getRuntimeConfig       : ({ commit, dispatch }, payload) => {
		Api.getRuntimeConfig("tg")
			 .then(res => {
				 const i18n             = res.data.i18n;
				 const filter_templates = {
					 "filter_templates_ru": res.data.filter_templates_ru,
					 "filter_templates_en": res.data.filter_templates_en,
				 };
				 const placeholders     = res.data.placeholders;

				 if(i18n) {
					 localStorage.setItem("i18n", JSON.stringify(i18n));
					 for(let loc in i18n) {
						 if(i18n.hasOwnProperty(loc)) {
							 payload.$i18n.mergeLocaleMessage(loc, i18n[ loc ]);
						 }
					 }
				 }

				 commit("update_filter_templates", filter_templates);
				 commit("update_placeholders", placeholders);
				 commit("update_runtime_config", res.data);
				 commit('commerce/TariffService/SET_TARIFFS_MAP', { tariffsMap: res.data.license_options })
			 })
			 .catch(err => {
				 console.error(err);
			 });
	},
	filterConfigCompare    : ({ state, getters }, configType) => {
	  return new Promise(function(resolve, reject) {
			const chat      = state.chatState.chat;
			const config    = chat.config;
			const chatLang  = state.chatState.chat.language_code;
			const templates = getters.getFilterTemplates[ `filter_templates_${ chatLang }` ];

			if(!isEmpty(chat) && !isUndefined(config)) {
				const currTemplates = templates[ configType ].templates;

				forIn(currTemplates, function(value, key) {
					const level          = parseInt(key.slice(-1));
					const templateConfig = value.config;

					if(recursiveCompareObjects(templateConfig, config)) {
					  resolve(level);
					}
				});
				resolve(-1);
			} else {
				reject("Can`t use config");
			}
		});
	},
	/**
	 *
	 * @param state
	 * @param commit
	 * @param payload = {configType, level}
	 * @returns {Q.Promise<any>}
	 */
	setFilterTemplateConfig: ({ state, commit, getters }, payload) => {
		return new Promise(function(resolve, reject) {
			const templates      = getters.getFilterTemplates;
			const chatLang       = state.chatState.chat.language_code;
			const currTemplates  = templates[ `filter_templates_${ chatLang }` ][ payload.configType ].templates;
			const neededTemplate = cloneDeep(currTemplates[ `level${ parseInt(payload.level) }` ].config);

			if(!isUndefined(neededTemplate)) {
				commit("setFilterTemplateConfig", {
					configType: payload.configType,
					config    : neededTemplate,
				});
				resolve("Good");
			} else {
				reject("Level is undefined");
			}
		});
	},
};

export default actions;
