import authAction     		from "./auth/actions";
import chatAction     		from "./chats/actions";
import triggersAction      from "./triggers/actions";
import postsPlannerActions from "./postsPlanner/actions"
import flowsActions from "./flow/actions"

const actions = {

	// ////////////////////////////////////////////
	// SIDEBAR & UI UX
	// ////////////////////////////////////////////

	// updateSidebarWidth({ commit }, width) {
	// 	commit("UPDATE_SIDEBAR_WIDTH", width);
	// },
	// toggleContentOverlay({ commit }) {
	// 	commit("TOGGLE_CONTENT_OVERLAY");
	// },
	// updateTheme({ commit }, val) {
	// 	commit("UPDATE_THEME", val);
	// },
	// updateWindowWidth({ commit }, width) {
	// 	commit("UPDATE_WINDOW_WIDTH", width);
	// },

	// ////////////////////////////////////////////
	// COMPONENT
	// ////////////////////////////////////////////

	// // VxAutoSuggest
	// updateStarredPage({ commit }, payload) {
	// 	commit("UPDATE_STARRED_PAGE", payload);
	// },

	//  The Navbar
	// arrangeStarredPagesLimited({ commit }, list) {
	// 	commit("ARRANGE_STARRED_PAGES_LIMITED", list);
	// },
	// arrangeStarredPagesMore({ commit }, list) {
	// 	commit("ARRANGE_STARRED_PAGES_MORE", list);
	// },
	// auth
	// ...authAction,
	// chat
	...chatAction,
	// triggers
	...triggersAction,
  	// posts planner
     	...postsPlannerActions,
  	// flows
  	...flowsActions,
};

export default actions;
