const mutations = {
	authError(state, n) {
		state.authData.error = n;
	},
	login(state, n) {
		state.authData.isLogged = n;
	},
};

export default mutations;
