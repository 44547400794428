import moment from "moment";
import { cloneDeep } from "lodash";
import { RawTriggersData } from "@/includes/logic/dashboard/types";
import { BaseTriggerSettings } from "@/components/TriggerSetup/logic/types/types";

export default class NewTriggerStatDataAdapter {
  _statisticsData;
  _fromDate;
  _toDate;
  series = new Array(8).fill(0);
  labels: Array<string> = [];
  total = 0
  chatTriggers: Array<BaseTriggerSettings> = []

  constructor(data: RawTriggersData, triggers: Array<BaseTriggerSettings>) {
    this.chatTriggers = triggers
    this._statisticsData = Object.values(data.triggers_stat);
    this._fromDate = data.period_start;
    this._toDate = data.period_end;
    this._getLabels();
    this._getTotalSeriesByTriggersActions();
    this._getTopTrigger()
  }

  _getLabels() {
    const from = moment(this._fromDate);
    const to = moment(this._toDate);

    while (!from.isAfter(to)) {
      const currDate = from.format("YYYY-MM-DD");
      this.labels.push(currDate);
      from.add(1, "day");
    }
  }

  _getTopTrigger() {
    let sortTriggers = this._statisticsData.sort((a, b) => b.count - a.count)
    return sortTriggers[0] ? this.chatTriggers.find(item => item.id === sortTriggers[0].trigger) : null
  }

  _getTotalSeriesByTriggersActions() {
    this._statisticsData.forEach(trigger => {
      const dateIndex = this.labels.indexOf(moment(trigger.time).format('YYYY-MM-DD'))
      this.series[dateIndex] += trigger.count
      this.total += trigger.count
    });
  }

  getData() {
    return {
      series: [ {
        data: this.series,
        name: 'dashboard_statistics_triggers_executions'
      } ],
      labels: this.labels,
      total: this.total,
      topTrigger: this._getTopTrigger()
    }
  }
}
