import { combinedRates } from "@/includes/logic/dashboard/types";

export const ratesKeys: Array<string> = [
  "FacecontrolRemoveBot", "FacecontrolRemoveBotUser",
  "EnterNewMember", "ReturnNewMember",
  "CommandUnwarn", "CommandReslim",
  "CommandReport", "CommandOn",
  "CommandOff", "CommandMute",
  "CommandKick", "CommandBan",
  "CommandUnban", "CommandUnmute",
  "CommandWarn", "CommandRooff",
  "CommandRo", "CommandDellog",
  "CommandRoall", "CommandRepin",
  "CommandUnrepin", "CommandBotaddadmin",
  "CommandBotdeladmin", "CommandSiteaddadmin",
  "CommandSitedeladmin", "FacecontrolRtl",
  "FacecontrolMinLen", "FacecontrolMaxLen",
  "GuardEditedMessage", "GuardNewLimit",
  "InactiveKick", "TriggerMute",
  "TriggerKick", "TriggerBan",
  "TriggerUnban", "TriggerUnmute",
  "TriggerWarn", "TriggerTriggerWarn",
  "CommandUn", "FacecontrolHieroglyph",
  "FacecontrolLangCode", "FacecontrolLoginMinLen",
  "FacecontrolLoginMaxLen", "UpdateSettings",
  "ReportAction", "RemoveUserByMathCaptcha",
  "UserCompleteCaptcha",
];

export const combinedRatesKeys: Array<string> = [
  "new_user_enter",
  "new_user_kicked",
  "new_user_allowed",
  "manual_admin_moderation",
  "reputation_call",
  "trigger_punish",
  "trigger_unpunish",
  "manual_user_moderation",
  "facecontrol",
  "guard",
  "commands",
  "trigger_call",
];
