import Vue                                      from "vue";
import i18n                                     from "@/i18n/i18n";
import Api                                      from "../../includes/logic/Api";
import router                                   from "@/router/router";
import moderation                               from "./chat/moderation/actions";
import chatActivityActions                      from "./chatActivitySettings/actions";
import chatRuntime                              from "./chatRuntime/actions";
import questionnaire                            from "./questionnaire/actions";
import statistics                               from "./statistics/actions";
import FiltersMap                               from "@/includes/logic/FiltersMap";
import { cloneDeep }                              from "lodash";
import { successNotification, errorNotification } from "@/includes/NotificationService";

function processChatConfigOnGet(config) {
  for(const key of FiltersMap.filtersList) {
    if(
	 config[ key ] !== undefined &&
	 config[ key ][ "new_limit" ] !== undefined &&
	 config[ key ][ "new_limit_msg_count" ] !== undefined
    ) {
      config[ key ][ "virtual_new" ] =
	   config[ key ][ "new_limit" ] !== 0 || config[ key ][ "new_limit_msg_count" ] !== 0;
    }
  }
}

function processChatConfigOnSave(config) {
  for(const key of FiltersMap.filtersList) {
    if(
	 config[ key ] !== undefined &&
	 config[ key ][ "virtual_new" ] !== undefined
    ) {
      delete config[ key ][ "virtual_new" ];
    }
  }
}

export default {
  requestChatsInfo({
		     state,
		     commit,
		   }) {
    return new Promise((resolve, reject) => {
      if(!state.chatState.chatsInfo.chats) {
	Vue.prototype.$baseTemplate.loader.open()
      }

      Api.getChatsInfo("tg")
	 .then(res => {
	   commit("updateChatsInfo", res.data);
	   resolve(res);
	 }).catch(error => {
	console.error(error);
	errorNotification(error);
	reject(error);
      }).finally(() => {
	Vue.prototype.$baseTemplate.loader.close()

	if(state.chatState.chatsInfo === undefined || Object.keys(state.chatState.chatsInfo).length === 0 || state.chatState.chatsInfo.chats.length === 0) {
	  commit("updateHaveChats", false);
	} else {
	  commit("updateHaveChats", true);
	}
      });
    });
  },
  requestChatConfig({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      Api.getChatConfig("tg", { chat_id: parseInt(id) })
	 .then(res => {
	   processChatConfigOnGet(res.data.chat.config);
	   commit("updateChat", res.data.chat);
	   resolve("Success!");
	 })
	 .catch(error => {
	   if (error.response) {
	     const errStatus = error.response.status;

	     if(parseInt(errStatus) === 405 || parseInt(errStatus) === 404) {
	       router.push({ name: "login" });
	     }
	   }

	   errorNotification(error);

	   reject(error);
	 });
    });
  },
  saveChatConfig({
		   state,
		   commit,
		 }) {
    // Сохранение настроек
    Vue.prototype.$confirm({
      title     : i18n.t("confirm_save_chat_config_title").toString(),
      content   : i18n.t("confirm_save_chat_config").toString(),
      okText    : i18n.t("accept").toString(),
      cancelText: i18n.t("reject").toString(),
      onOk      : function() {
	Vue.prototype.$baseTemplate.loader.open()

	processChatConfigOnSave(state.chatState.chat.config);

	Api.setChatConfig("tg", {
	  chat_id: parseInt(state.chatState.chat.chat_id),
	  chat   : {
	    config                  : state.chatState.chat.config,
	    chatAdmins              : state.chatState.chat.chatAdmins,
	    usersIgnoredByFilters   : state.chatState.chat.usersIgnoredByFilters,
	    reportAdmins            : state.chatState.chat.reportAdmins,
	    language_code           : state.chatState.chat.language_code,
	    admins                  : state.chatState.chat.admins,
	    triggers                : state.chatState.chat.triggers,
	    enterProtectionFormUsers: state.chatState.chat.enterProtectionFormUsers,
	    reputationUsersWhitelist: state.chatState.chat.reputationUsersWhitelist,
	    timezone                : state.chatState.chat.timezone,
	  },
	})
	   .then((res) => {
	     // update chat state
	     processChatConfigOnGet(res.data.chat.config);
	     commit("updateChat", res.data.chat);
	     commit("updatedTriggerId", null);

	     successNotification(i18n.t("success").toString(), i18n.t("configuration_uploaded").toString());

	   }).catch(error => {
	  console.error(error);
	  errorNotification(error);
	}).finally(() => {
	  Vue.prototype.$baseTemplate.loader.close()
	});
      },
    });
  },
  // export of chat configuration
  exportChatConfig(_, {
    id,
    triggers = null,
  }) {
    Api.exportChatConfig("tg", {
      chat_id: id,
      triggers,
    });
  },
  // brand
  setChatBrand({
		 state,
		 commit,
	       }, { token }) {
    Vue.prototype.$baseTemplate.loader.open()
    Api.setChatBrand("tg", {
      chat_id: parseInt(state.chatState.chat.chat_id),
      token  : token,
    })
       .then((res) => {
	 // update chat state
	 commit("updateChat", res.data.chat);

	  successNotification()
       })
       .catch(error => {
	 if(error.response !== undefined) {
	   errorNotification(error)
	 }
       })
       .finally(() => {
	 Vue.prototype.$baseTemplate.loader.close()
       });
  },
  resetChatBrand({
		   state,
		   commit,
		 }) {
    Vue.prototype.$baseTemplate.loader.open()
    return new Promise(function(resolve, reject) {
      Api.resetChatBrand("tg", { chat_id: parseInt(state.chatState.chat.chat_id) })
	 .then(res => {
	   // update chat state
	   commit("updateChat", res.data.chat);
	   successNotification();
	   resolve();
	 })
	 .catch(error => {
	   if(error.response !== undefined) {
	     errorNotification(error);
	     reject(error);
	   }
	 })
	 .finally(() => {
	   Vue.prototype.$baseTemplate.loader.close()
	 });
    });
  },
  getBrandConfig({
		   state,
		   commit,
		 }, { token_hash }) {
	      Vue.prototype.$baseTemplate.loader.open()
    return Api.getBrandConfig("tg", { token_hash })
	      .then((res) => {
		// update chat state
		commit("SET_BRAND_BOT_CONFIG", res.data.config);
	      })
	      .catch(error => {
		if(error.response !== undefined) {
		  errorNotification(error);
		}
	      })
	      .finally(() => {
		Vue.prototype.$baseTemplate.loader.close()
	      });
  },
  saveBrandConfig({ state, commit }, { token_hash }) {
    Vue.prototype.$baseTemplate.loader.open()
    Api.setBrandConfig("tg", {
      token_hash,
      config: state.chatState.brandConfig,
    }).then(() => {
	successNotification()
    }).catch(error => {
      if(error.response !== undefined) {
	errorNotification(error)
      }
    }).finally(() => {
      Vue.prototype.$baseTemplate.loader.close()
    });
  },
  hideSaveChatConfigButton({ commit }) {
    commit("saveChatConfigButtonToggle", false);
  },
  showSaveChatConfigButton({ commit }) {
    commit("saveChatConfigButtonToggle", true);
  },
  // Chat activity actions
  ...chatActivityActions,
  // Chat runtime actions
  ...chatRuntime,
  ...moderation,
  ...statistics,
  ...questionnaire,
};
