// NPM
import { isEmpty } from "lodash";
import Vue       from "vue";
import Router    from "vue-router";
// Project
import checkChat from "@/assets/utils/checkChat";
import ReturnUrl from "@/includes/logic/ReturnUrl";
import VuAuth    from "@/includes/logic/VuAuth";
import store     from "@/store/store";
import routes    from "@/router/routes";

import * as Sentry    from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.use(Router);

const router = new Router({
  mode  : "history",
  base  : process.env.BASE_URL,
  scrollBehavior(to) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
	if(to.hash) {
	  resolve({ selector: to.hash })
	} else {
	  resolve({
	    x: 0,
	    y: 0,
	  })
	}
      }, 600)
    })
  },
  routes: [],
});
//
// router.afterEach((to, from) => {
//   const appLoading = document.getElementById("loading-bg");
//   if(appLoading) {
//     appLoading.classList.replace("opacity-100", "opacity-0");
//     setTimeout(() => {
//       appLoading.classList.add("hidden");
//     }, 160);
//
//     if(to.name === "login" || to.name === "login_admin" || to.name === "screenshot") {
//       clearTimeout(store.state.loginTimeout);
//     } else {
//       const timeoutTimerId     = setTimeout(() => {
// 	callVuAuthCheckLogin().catch(() => router.resolve({name: "login"}));
//       }, 60000);
//       store.state.loginTimeout = timeoutTimerId;
//     }
//   }
//   if(to.path !== router.resolve({name: "login"}).href &&
//        to.path !== router.resolve({name: "login_admin"}).href) {
//     ReturnUrl.set(window.location.href);
//   }
// });
//
// router.beforeEach((to, from, next) => {
//   if(
//        to.path !== router.resolve({name: "login"}).href &&
//        to.path !== router.resolve({name: "login_admin"}).href &&
//        to.path !== router.resolve({name: "screenshot"}).href
//   ) {
//     const onLoginCheck = () => {
//       return new Promise(resolve => {
//
// 	if(store.state.chatState.chatsInfo.length === 0) {
// 	  store.dispatch("requestChatsInfo");
// 	}
//
// 	const chatData = store.state.chatState.chat;
//
//
// 	// Return Url
// 	// if(Vue.prototype.$returnUrlHelper.getUrl()) {
// 	// // Vue.prototype.$returnUrlHelper.go();
// 	// next();
// 	// }
//
//
// 	if(checkChat(to.path)) {
//
// 	  const name   = to.name;
// 	  const chatId = to.params.id;
//
// 	  const baseChatTitle   = `${chatId}`;
// 	  const baseChatUrl     = router.resolve({
// 	    name  : "dashboard",
// 	    params: {id: chatId},
// 	  }).href;
// 	  const betaVersionText = "<small>(<b>β</b>-version)</small>";
//
// 	  const breadcrumbs = to.meta.breadcrumb = [
// 	    {
// 	      title: "Chats",
// 	      url  : "/",
// 	    },
// 	  ];
//
//
// 	  // push actual breadcrumbs
// 	  switch(name) {
// 	    case "dashboard":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 	      }, {
// 		title : "Dashboard",
// 		active: true,
// 	      });
// 	      break;
// 	    case "greeting":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Greeting",
// 		active: true,
// 	      });
// 	      break;
// 	    case "reputation":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Reputation",
// 		active: true,
// 	      });
// 	      break;
// 	    case "reputation_extended":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : `Advanced reputation ${betaVersionText}`,
// 		active: true,
// 	      });
// 	      break;
// 	    case "reputation_extended_xp":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : `XP ${betaVersionText}`,
// 		active: true,
// 	      });
// 	      break;
// 	    case "reputation_extended_triggers":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : `Special reputation triggers ${betaVersionText}`,
// 		active: true,
// 	      });
// 	      break;
// 	    case "manual_moderation":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Manual moderation",
// 		active: true,
// 	      });
// 	      break;
// 	    case "journal":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Journal",
// 		active: true,
// 	      });
// 	      break;
// 	    case "chat_properties":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Chat properties",
// 		active: true,
// 	      });
// 	      break;
// 	    case "extra":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Extra",
// 		active: true,
// 	      });
// 	      break;
// 	    case "chat_triggers":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Triggers",
// 		active: true,
// 	      });
// 	      break;
// 	    case "buy":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Buy",
// 		active: true,
// 	      });
// 	      break;
// 	    case "statistics":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : `Statistics ${betaVersionText}`,
// 		active: true,
// 	      });
// 	      break;
// 	    case "statistics_users":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title: `Statistics ${betaVersionText}`,
// 		url  : router.resolve({
// 		  name  : "statistics_users",
// 		  params: {id: chatId},
// 		}).href,
// 	      }, {
// 		title : "Users",
// 		active: true,
// 	      });
// 	      break;
// 	    case "statistics_referrals":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title: `Statistics ${betaVersionText}`,
// 		url  : router.resolve({
// 		  name  : "statistics_referrals",
// 		  params: {id: chatId},
// 		}).href,
// 	      }, {
// 		title : "Referrals",
// 		active: true,
// 	      });
// 	      break;
// 	    case "chat_trigger":
// 	      let newMeta = {
// 		title : "",
// 		active: true,
// 	      };
//
// 	      if(to.params.actionType === "new") {
// 		newMeta.title = "New trigger";
// 	      }
//
// 	      if(to.params.actionType === "edit") {
// 		newMeta.title = "Edit trigger";
// 	      }
//
// 	      if(to.params.actionType === "watch") {
// 		newMeta.title = "Watch trigger";
// 	      }
//
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title: "Triggers",
// 		url  : router.resolve({
// 		  name  : "chat_triggers",
// 		  params: {id: chatId},
// 		}).href,
// 	      }, newMeta);
//
// 	      break;
// 	    case "anchors":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Anchors",
// 		active: true,
// 	      });
// 	      break;
// 	    case "questionnaire":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Questionnaire",
// 		active: true,
// 	      });
// 	      break;
// 	    case "journal_log":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title: "Journal",
// 		url  : router.resolve({
// 		  name  : "journal",
// 		  params: {id: chatId},
// 		}).href,
// 	      }, {
// 		title : "Log",
// 		active: true,
// 	      });
// 	      break;
// 	    case "questionnaire_form":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title: "Questionnaire",
// 		url  : router.resolve({
// 		  name  : "questionnaire",
// 		  params: {id: chatId},
// 		}).href,
// 	      }, {
// 		title : "Form",
// 		active: true,
// 	      });
// 	      break;
// 	    case "reputation_chart":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title: "Reputation",
// 		url  : router.resolve({
// 		  name  : "reputation",
// 		  params: {id: chatId},
// 		}).href,
// 	      }, {
// 		title : "Chart",
// 		active: true,
// 	      });
// 	      break;
// 	    case "users_rights":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Users Rights",
// 		active: true,
// 	      });
// 	      break;
// 	    case "branding":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Branding",
// 		active: true,
// 	      });
// 	      break;
// 	    case "report_system":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Complaint System",
// 		active: true,
// 	      });
// 	      break;
// 	    case "chat_rules":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Chat Rules",
// 		active: true,
// 	      });
// 	      break;
// 	    case "chat_activity_support":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Chat Activity Support",
// 		active: true,
// 	      });
// 	      break;
// 	    case "warning_system":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Warning System",
// 		active: true,
// 	      });
// 	      break;
// 	    case "filters":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Filters",
// 		active: true,
// 	      });
// 	      break;
// 	    case "moderation_extra":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Moderation Extra",
// 		active: true,
// 	      });
// 	      break;
// 	    case "filter":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title: "Filters",
// 		url  : router.resolve({
// 		  name  : "filters",
// 		  params: {id: chatId},
// 		}).href,
// 	      }, {
// 		title : "Filter",
// 		active: true,
// 	      });
// 	      break;
// 	    case "face_control_page":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Face Control",
// 		active: true,
// 	      });
// 	      break;
// 	    case "external_integrations":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "External Integrations",
// 		active: true,
// 	      });
// 	      break;
// 	    case "top_message_config":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Tops customisation",
// 		active: true,
// 	      });
// 	      break;
// 	    case "rank_system":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Rank system",
// 		active: true,
// 	      });
// 	      break;
// 	    case "workflow_list":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Flow",
// 		active: true,
// 	      });
// 	      break;
// 	    case "referral_system":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Referral system",
// 		active: true,
// 	      });
// 	      break;
// 	    case "invite_links_list":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Invite Links List",
// 		active: true,
// 	      });
// 	      break;
// 	    case "invite_links_statistics":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Invite Links Statistics",
// 		active: true,
// 	      });
// 	      break;
// 	    case "catalog_integration":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "tgpulse.com",
// 		active: true,
// 	      });
// 	      break;
// 	    case "posts_planner":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title: `Board (${to.params.boardId})`,
// 		url  : router.resolve({
// 		  name  : "boards",
// 		  params: {id: chatId},
// 		}).href,
// 	      }, {
// 		title : "Posts Planner",
// 		active: true,
// 	      });
// 	      break;
// 	    case "boards":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Boards",
// 		active: true,
// 	      });
// 	      break;
// 	    case "post":
// 	      let postMeta = {
// 		title : "",
// 		active: true,
// 	      };
//
// 	      if(to.params.actionType === "new") {
// 		postMeta.title = "New Post";
// 	      }
//
// 	      if(to.params.actionType === "edit") {
// 		postMeta.title = "Edit Post";
// 	      }
//
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title: `Board (${to.params.boardId})`,
// 		url  : router.resolve({
// 		  name  : "boards",
// 		  params: {id: chatId},
// 		}).href,
// 	      }, {
// 		title: "Posts planner",
// 		url  : router.resolve({
// 		  name  : "posts_planner",
// 		  params: {
// 		    id     : chatId,
// 		    boardId: to.params.boardId,
// 		  },
// 		}).href,
// 	      }, {
// 		title : "Post",
// 		active: true,
// 	      }, postMeta);
// 	      break;
// 	    case "new_filters":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title : "Filters",
// 		active: true,
// 	      });
// 	      break;
// 	    case "new_filter":
// 	      breadcrumbs.push({
// 		title: baseChatTitle,
// 		url  : baseChatUrl,
// 	      }, {
// 		title: "Filters",
// 		url  : router.resolve({
// 		  name  : "new_filters",
// 		  params: {id: chatId},
// 		}).href,
// 	      }, {
// 		title : "Filter",
// 		active: true,
// 	      });
// 	      break;
//
// 	  }
//
// 	  store.commit("UPDATE_BREADCRUMBS", breadcrumbs);
//
// 	  if(chatData.chat_id === undefined || parseInt(chatData.chat_id) !== parseInt(to.params.id)) {
// 	    store.dispatch("requestChatConfig", {
// 	      id: to.params.id,
// 	      vm: Vue.prototype,
// 	    }).catch(err => {
// 	      console.error(err);
// 	    }).finally(() => {
// 	      resolve();
// 	    });
// 	  } else {
// 	    resolve();
// 	  }
// 	} else if(to.path.includes("/board")) {
// 	  if(isEmpty(store.state.postsPlanner.boards)) {
// 	    store.dispatch("requestPostBoards")
// 		 .then(() => {
// 		   resolve();
// 		 })
// 		 .finally(() => {
// 		 });
// 	  } else {
// 	    resolve();
// 	  }
// 	} else {
// 	  if(Object.keys(chatData).length !== 0) {
// 	    setTimeout(() => {
// 	      store.commit("updateChat", {});
// 	      store.commit("resetUnsavedTriggers");
// 	      store.commit("resetFrontendTriggers");
// 	    }, 200);
// 	  }
// 	  resolve();
// 	}
//       });
//     };
//
//     clearTimeout(store.state.loginTimeout);
//
//     const lastCheckLogin = sessionStorage.getItem("lastCheckLogin");
//     if(store.state.loginTimeout === null || isEmpty(store.state.userState.user) || lastCheckLogin === null || Date.now() - parseInt(lastCheckLogin) > 60000) {
//       callVuAuthCheckLogin()
// 	   .then(() => {
// 	     onLoginCheck()
// 		  .then(() => {
// 		    if(Vue.prototype.$returnUrlHelper.getUrl() && (from.name === "login" || from.name === "login_admin")) {
// 		      Vue.prototype.$returnUrlHelper.go();
// 		    } else {
// 		      next();
// 		    }
// 		  });
// 	   })
// 	   .catch(() => next({name: "login"}));
//     } else {
//       onLoginCheck().then(() => next());
//     }
//   } else {
//     if(to.name === "login" || to.name === "login_admin") {
//       callVuAuthCheckLogin()
// 	   .then(() => {
// 	     if(Vue.prototype.$returnUrlHelper.getUrl()) {
// 	       Vue.prototype.$returnUrlHelper.go();
// 	     } else {
// 	       next({name: "Chats"});
// 	     }
// 	   })
// 	   .catch(() => next());
//     } else {
//       next();
//     }
//   }
// });

// router.onError(error => {
//   if(/loading chunk \d* failed./i.test(error.message)) {
//     window.location.reload();
//   }
// });
//
//
// const callVuAuthCheckLogin = () => {
//   return new Promise((resolve, reject) => {
//     VuAuth.checkLogin()
// 	  .then(res => {
// 	    const userData = res.data;
// 	    const user     = {
// 	      name             : userData.name,
// 	      about            : userData.login,
// 	      img              : userData.avatar,
// 	      languageCode     : userData.languageCode,
// 	      has_questionnaire: userData.has_questionnaire,
// 	      registration     : userData.registration,
// 	      userId           : userData.user_id,
// 	    };
//
// 	    store.commit("updateUserInfo", user);
//
// 	    resolve(true);
//
// 	  })
// 	  .catch(error => {
// 	    const notifyData = {
// 	      title: "Error",
// 	      text : "",
// 	      color: "warning",
// 	      icon : "error",
// 	    };
//
// 	    //return Url
// 	    // ReturnUrl.set(location.href);
//
// 	    if(error.response !== undefined && error.response.status !== undefined && parseInt(error.response.status) === 403) {
// 	      notifyData.title = "Auth error";
// 	      notifyData.text  = error.response.data.message;
// 	      Vue.prototype.$vs.notify(notifyData);
// 	      // router.push({name: "login"}).catch(rej => console.warn(rej));
// 	    } else {
// 	      // handling server errors
// 	      notifyData.text  = error;
// 	      notifyData.color = "danger";
// 	      Vue.prototype.$vs.notify(notifyData);
// 	    }
// 	    // router.resolve({name: "chats"});
// 	    store.commit("login", false);
// 	    reject();
// 	  })
// 	  .finally(function() {
// 	    sessionStorage.setItem("lastCheckLogin", String(Date.now()));
// 	    Vue.prototype.$vs.loading.close();
// 	  });
//   });
// };
//

Sentry.init({
  Vue,
  dsn             : "https://5770356df02e415db9cbcdf54425feec@cabinet.chatkeeper.app/clientErrorHandler/2",
  integrations    : [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 20.0,
});

export default router;
