export default {
	deferredRemoveKarmaBotMessages(state) {
		state.chatState.chat.config.deferred_remove_karma_bot_messages = +!(+state.chatState.chat.config.deferred_remove_karma_bot_messages);
	},
	useKarma(state) {
		state.chatState.chat.config.use_karma = +!(+state.chatState.chat.config.use_karma);
	},
	addKarmaWords(state, val) {
		state.chatState.chat.config.karma_words = val;
	},
	removeKarmaWords(state, val) {
		const arr   = state.chatState.chat.config.karma_words;
		const index = arr.indexOf(val);
		
		if(index > -1) {
			arr.splice(index, 1);
		}
	},
	karmaMessage(state, val) {
		state.chatState.chat.config.karma_message = val;
	},
	useAntiKarma(state) {
		state.chatState.chat.config.use_anti_karma = +!(+state.chatState.chat.config.use_anti_karma);
	},
	addAntiKarmaWords(state, val) {
		state.chatState.chat.config.anti_karma_words = val;
	},
	removeAntiKarmaWords(state, val) {
		const arr   = state.chatState.chat.config.anti_karma_words;
		const index = arr.indexOf(val);
		
		if(index > -1) {
			arr.splice(index, 1);
		}
	},
	antiKarmaMessage(state, val) {
		state.chatState.chat.config.anti_karma_message = val;
	},
};
