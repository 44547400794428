const getters = {
	getHaveLicense     : state => {
		return parseInt(state.chatState.chat.license_type) === 2;
	},
	getHaveBrand       : state => {
		return state.chatState.chat.brand !== undefined && state.chatState.chat.brand !== null;
	},
	isChatLicenseExists: state => {
		return state.chatState.chat.license_left > 0 && state.chatState.chat.license_timestamp !== "";
	},
	isChatAtLeastAdvanced	   : state => {
	  return parseInt(state.chatState.chat.license_type) >= 1;
	},
	isChatForum	   : state => {
	  return state.chatState.chat.type === 'forum';
	},
	hasHiddenChats: state => {
		const chats = state.chatState.chatsInfo?.chats

		if (chats && chats.length > 0) {
			return chats.some(chat => chat.hidden)
		}

		return false
	},
	hasAtLeastOneAdvanced: state => {
		const chats = state.chatState.chatsInfo?.chats

		if (chats && chats.length > 0) {
			return chats.some(chat => chat.license_type >= 1)
		}

		return false
	}

};

export default getters;
