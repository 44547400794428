export default {
	mute_command_text(state, val) {
		state.chatState.chat.config.mute_command_text = val;
	},
	mute_command_reply(state) {
		state.chatState.chat.config.mute_command_reply = +!(+state.chatState.chat.config.mute_command_reply);
	},
	mute_command_remove(state) {
		state.chatState.chat.config.mute_command_remove = +!(+state.chatState.chat.config.mute_command_remove);
	},
};
