import { isUndefined, set, merge }                     from "lodash";
import branding              from "./chat/branding";
import greeting              from "./chat/greeting";
import journal               from "./chat/journal";
import mainConfigs           from "./chat/mainConfigs";
import moderation            from "./chat/moderation/mutations";
import reputation            from "./chat/reputation";
import chatRuntimeMutations  from "./chatRuntime/mutations";
import statistics            from "./statistics/mutations";
import chatTriggersMutations from "./triggers/mutations";
import deepObjectKeyPut      from "@/assets/utils/deepObjectKeyPut";
import deepMergeObjects      from "@/assets/utils/deepMergeObjects";

export default {
	// Основныйе настройки
	...mainConfigs,
	// Приветсвие
	...greeting,
	// Репутация
	...reputation,
	// Журнал
	...journal,
	// Модерация
	...moderation,
	// haveChats
	updateHaveChats(state, n) {
		state.chatState.haveChats = n; // true/false
	},
	SET_BRAND_BOT_CONFIG(state, n) {
		state.chatState.brandConfig = n; // object/null
	},
	...branding,
	...chatRuntimeMutations,
	...chatTriggersMutations,
	...statistics,
	saveChatConfigButtonToggle(state, val) {
		state.chatState.displaySaveChatConfigButton = val;
	},
	setChatField(state, { objectPath, value }) {
		if(!isUndefined(state.chatState.chat)) {
			set(state.chatState.chat, objectPath, value);
		}
	},
	setObjectField(state, { object, objectPath, value }) {
		deepObjectKeyPut(object, objectPath, value);
	},
	mergeObjects(state, { target, src }) {
		merge(target, src);
	},
	DEEP_MERGE_OBJECTS(state, { target, src }) {
		deepMergeObjects(target, src);
	},
	MERGE_CHAT_CONFIG: (state, { chatConfig }) => {
		deepMergeObjects(state.chatState.chat, chatConfig);
	},

  	EXEC_CALLBACK_IN_STORE(state, { type = 'unknown', callback }) {
	  callback()
	}
};
