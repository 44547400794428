import chatGetters             from "./chats/getters";
import userGetters             from "./user/getters";
import moderationFilterGetters from "./moderation-filters/getters";
import postsPlannerGetters     from "@/store/postsPlanner/getters";

const getters = {
  getFilterTemplates : (state) => {
    return {
      "filter_templates_ru": state.AdminTemplate.runtime_config.filter_templates_ru,
      "filter_templates_en": state.AdminTemplate.runtime_config.filter_templates_en,
    };
  },
  isInfibotProject   : (state) => process.env.VUE_APP_TARGET_PROJECT === "infibot",
  isChatkeeperProject: (state) => process.env.VUE_APP_TARGET_PROJECT === "chatkeeperbot",
  // COMPONENT
  // vx-autosuggest
  // starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  ...userGetters,
  // Chat
  ...chatGetters,
  // vu-components
  ...moderationFilterGetters,
  ...postsPlannerGetters,

};

export default getters;
