export default {
  set_flows_to_store(state, flows) {
    state.flows.flows = flows;
  },

  set_flow_to_brand(state, flow) {
    state.chatState.brandConfig.flow = flow.id
  },

  swap_chat_brand(state, brand) {
    state.chatState.brandConfig = brand
  },

  add_flow(state, { key, title }) {
    state.flows.flows.unshift({ key: key, title: title })
  },

  delete_flow(state, { index }) {
    state.flows.flows.splice(index, 1)
  }
};
