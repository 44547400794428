import router from "@/router/router";

import Store                 from "@/store/store";
import Api                   from "./Api";
import { successNotification } from "@/includes/NotificationService";

export default class VuAuth {
	static STORE = Store;

	static login(login, password, vm) {
		vm.$vs.loading();
		let isLogged = false;
		Api.login("tg", {
			login,
			password,
		})
			 .then(res => {
				 if(res.data.result === "ok") {
					 isLogged = true;
					 this.STORE.commit("login", isLogged);
					 this.STORE.dispatch("requestChatsInfo");
				 }
				 this.STORE.dispatch("authError", "");
			 }).catch(error => {
			if(error.response !== undefined && parseInt(error.response.status) === 403) {
				this.STORE.dispatch("authError", "warn");
				router.push({ name: "login" }).catch(err => console.warn(err));
			} else {
				this.STORE.dispatch("authError", "error");
			}
		}).finally(() => {
			const err = this.STORE.state.authData.error;

			if(err !== "") {
				let notifyData = {};

				switch(err) {
					case "warn":
						notifyData = {
							title: "Auth error",
							text : "Please check your Login and Password",
							color: "warning",
							icon : "error",
						};
						break;
					case "error":
						notifyData = {
							title: "Unusual Error",
							text : "Please try again later",
							color: "warning",
							icon : "error",
						};
						break;
				}

				successNotification(notifyData)
			}

			vm.$vs.loading.close();

			if(isLogged) vm.$router.push({ name: "chats" });

		});
	}

	static checkLogin() {
		return Api.getMe("tg");
	}

	static logout() {
		return Api.logout("tg");
	}
}
